import React from 'react';
import ReactDOM from 'react-dom';
import { TripFinder } from './trip_finder.jsx';

const div = document.getElementById('trip_finder');

if (div) {

  const CMS_LINK = div.dataset.cms_link;
  const heading = div.dataset.heading;
  const currency_id = 2;
  const hide_sidebar = div.dataset.hide_sidebar;

  ReactDOM.render(
    <TripFinder
      currency_id={currency_id}
      cms_link={CMS_LINK}
      heading={heading}
      hide_sidebar={hide_sidebar}
    />,
    div
  );
}