import React from 'react';
import ReactDOM from 'react-dom';


var icons = {
    'Guided Activity': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <polygon points="47.1,17.1 45.4,15.5 31.3,15.5 31.3,10.8 28.1,10.8 28.1,15.5 17,15.5 17,22.1 28.1,22.1 28.1,27.1 14.5,27.1
        12.9,28.8 11.3,30.5 12.9,32.1 14.5,33.7 28.1,33.7 28.1,48.5 31.3,48.5 31.3,33.7 42.9,33.7 42.9,27.1 31.3,27.1 31.3,22.1
        45.4,22.1 47.1,20.4 48.7,18.8 "/>
    </svg>,
    'Property Based': <svg className="self-center fill-current w-6 h-6"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M16.6,37.7h6.2v-6.2h-6.2C16.6,31.5,16.6,37.7,16.6,37.7z M43.8,31.5h-6.2v6.2h6.2V31.5z M30,12.1L9.4,24.9v23h41.2v-23
        L30,12.1z M47.3,44.5H33.6v-13h-7.2v13H12.8V26.8L30,16.1l17.1,10.7v17.8L47.3,44.5L47.3,44.5z"/>
    </svg>,
    'Group Tour': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <g id="Path_41_1_">
            <path d="M41.2,38.5v4.4h-2.1v-4.4c0-2.2-1.8-3.9-3.9-3.9h-10c-2.2,0-3.9,1.8-3.9,3.9v4.4h-2.1v-4.4c0-3.3,2.7-6,6-6h10
                C38.5,32.5,41.2,35.2,41.2,38.5z"/>
            <path d="M41.2,38.5v4.4h-2.1v-4.4c0-2.2-1.8-3.9-3.9-3.9h-10c-2.2,0-3.9,1.8-3.9,3.9v4.4h-2.1v-4.4c0-3.3,2.7-6,6-6h10
                C38.5,32.5,41.2,35.2,41.2,38.5z"/>
        </g>
        <g id="Ellipse_2_1_">
            <path d="M30.3,17.1c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1S33.7,17.1,30.3,17.1z M30.3,27.1c-2.2,0-4-1.8-4-4
                s1.8-4,4-4s4,1.8,4,4C34.3,25.3,32.5,27.1,30.3,27.1z"/>
            <path d="M30.3,17.1c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1S33.7,17.1,30.3,17.1z M30.3,27.1c-2.2,0-4-1.8-4-4
                s1.8-4,4-4s4,1.8,4,4C34.3,25.3,32.5,27.1,30.3,27.1z"/>
        </g>
        <g id="Path_41_3_">
            <path d="M20,33.5c-0.4,0.4-0.8,0.9-1.1,1.4c-0.2-0.1-0.4-0.1-0.7-0.1h-7.8c-1.7,0-3.1,1.4-3.1,3.1v3.5H5.6v-3.5
                c0-2.6,2.1-4.7,4.7-4.7h7.8C18.9,33.1,19.5,33.3,20,33.5z"/>
        </g>
        <g id="Ellipse_2_3_">
            <path d="M14.4,21.1c-2.6,0-4.7,2.1-4.7,4.8c0,2.6,2.1,4.7,4.7,4.7s4.8-2.1,4.8-4.7C19.2,23.2,17.1,21.1,14.4,21.1z M14.4,29
                c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C17.6,27.6,16.2,29,14.4,29z"/>
        </g>
        <g id="Path_41_4_">
            <path d="M40,33.5c0.4,0.4,0.8,0.9,1.1,1.4c0.2-0.1,0.4-0.1,0.7-0.1h7.8c1.7,0,3.1,1.4,3.1,3.1v3.5h1.7v-3.5c0-2.6-2.1-4.7-4.7-4.7
                h-7.8C41.1,33.1,40.5,33.3,40,33.5z"/>
        </g>
        <g id="Ellipse_2_4_">
            <path d="M40.8,25.9c0,2.6,2.1,4.7,4.8,4.7c2.6,0,4.7-2.1,4.7-4.7s-2.1-4.8-4.7-4.8C42.9,21.1,40.8,23.2,40.8,25.9z M42.4,25.9
                c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1S47.2,29,45.5,29S42.4,27.6,42.4,25.9z"/>
        </g>
    </svg>,
    'Cruise': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path id="ship" d="M45.1,31.3l-3.9-6.9h-2.7L35.4,19h-2.8l3.1,5.5h-2.7L30,19.2h-5.1l-3.1,5.3h-2.2l-3.9,6.9h-4.2l-5.3,9.2h42.6
        l5.3-9.2H45.1z M26.2,21.5h2.2l1.6,2.7h-5.3L26.2,21.5z M18.5,31.3l2.5-4.4h18.9l2.5,4.4H18.5z"/>
    </svg>,
    'Independent': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M55.3,29.7L52,20c-0.6-1.7-2.2-2.9-4-2.9H8c-2,0-3.6,1.6-3.6,3.6v15.8c0,2,1.6,3.6,3.6,3.6h0.7c0.6,1.9,2.4,3.2,4.4,3.2
        c2.1,0,3.8-1.4,4.4-3.2c0.6,1.9,2.4,3.2,4.4,3.2c2.1,0,3.8-1.4,4.4-3.2h15.3c0.6,1.9,2.4,3.2,4.4,3.2c2.1,0,3.8-1.4,4.4-3.2H52
        c2,0,3.6-1.6,3.6-3.6v-5.5C55.6,30.6,55.5,30.1,55.3,29.7z M13.1,40.5c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
        C14.9,39.7,14.1,40.5,13.1,40.5z M22,40.5c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8C23.8,39.7,23,40.5,22,40.5z
        M38.9,27.2H7.3v-6.4C7.3,20.3,7.7,20,8,20h30.9V27.2z M46.1,40.5c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8
        C47.9,39.7,47.1,40.5,46.1,40.5z M44.5,27.2h-2.7V20H48c0.6,0,1.1,0.4,1.3,1l3.2,9.2L44.5,27.2z"/>
    </svg>,
    'Private': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <g transform="translate(1208.799 482)">
                <path d="M-1166.8-434.2v-6.1c0-3-2.4-5.4-5.4-5.4h-13.7c-3,0-5.4,2.4-5.4,5.4v6.1h-2.8v-6.1c0-4.6,3.8-8.3,8.3-8.3h13.7
                    c4.6,0,8.3,3.8,8.3,8.3v6.1H-1166.8z"/>
                <path d="M-1178.9-453.1c-4.6,0-8.3-3.8-8.3-8.3c0-4.6,3.8-8.3,8.3-8.3c4.6,0,8.3,3.8,8.3,8.3
                    C-1170.5-456.7-1174.2-453.1-1178.9-453.1z M-1178.9-466.8c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5c3,0,5.5-2.5,5.5-5.5
                    S-1175.7-466.8-1178.9-466.8z"/>
        </g>
    </svg>,
    'Trekking': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <polygon points="22.6,48.4 27.8,35 33,48.4 36.9,48.4 29.7,30 32.1,23.6 33.8,27.8 40,27.8 40,48.4 42.4,48.4 42.4,27.8 43.6,27.8
            43.6,24.2 42.4,24.2 42.4,16.5 40,16.5 40,24.2 36.2,24.2 34.1,18.6 34.2,18.6 34.1,18.6 34.1,18.5 34,18.5 30.8,17.3 18.8,48.4
            "/>
        <circle cx="34.1" cy="14" r="2.6"/>
        <path d="M29.4,14.1l-6.7-2.6l-5.8,14.7l6.7,2.6L29.4,14.1z M20,24.8l4-10.3l2.3,0.9l-4,10.3L20,24.8z"/>
    </svg>,
    'Horseriding': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M27.4,16.4c1.2,0,2.2-0.9,2.2-2.2S28.6,12,27.4,12c-1.2,0-2.2,0.9-2.2,2.2S26.1,16.4,27.4,16.4z"/>
    <path d="M48.7,23.3l-4.8-4.4v-2.4L35,24.9l-5.4-4v-3.5h-4.7v9.2h-8.2l0,0h-0.5c-2.4,0-4.5,2.1-4.5,4.5v5.4h2.9v-5.4
        c0-0.9,0.7-1.6,1.6-1.6h0.5v6.3l-1.4,1.4V48h3.6v-9.3l1.9-1.9l-0.6,1.1L23.9,48h3.8L24,38.1l0.9-1.7h8.2V48h3.6V35.9L43,25.1l4,1.3
        C48.8,26.9,50.1,24.6,48.7,23.3z M33.1,26.6h-3.6v-2.8L33.1,26.6L33.1,26.6z"/>
    </svg>,
    // 'Kayaking': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    //     <path d="M45.4,35.5L42.8,34c-1.1-0.7-2.5-0.5-3.4,0.3l-2.6-1.5c0.1-0.9,0.1-1.8,0.1-2.8c0-6.6-2.1-12.8-6.1-18.1l-0.8-1l-0.9,1
    //         c-3,3.9-4.9,8.4-5.7,13.2l-1.8-1c0.2-1.2-0.3-2.5-1.5-3.1l-2.7-1.5c-1.5-0.8-3.3-0.4-4.1,1.1l0,0c-0.8,1.5-0.4,3.3,1.1,4.1l2.7,1.5
    //         c1.1,0.7,2.5,0.5,3.4-0.3l2.5,1.5c-0.1,0.9-0.1,1.8-0.1,2.8c0,6.6,2.1,12.8,6.1,18.1l0.8,1l0.9-1c3-3.9,4.9-8.4,5.7-13.2l1.9,1.1
    //         c-0.2,1.2,0.3,2.5,1.5,3.1l2.7,1.5c1.5,0.8,3.2,0.4,4.1-1.1l0,0C47.3,38.1,46.8,36.3,45.4,35.5z M29.9,45.8C26.7,41.2,25,35.7,25,30
    //         c0-0.6,0-1.1,0.1-1.7l0.9,0.5c0,0.1,0,0.1,0,0.2v1.9c0,2.2,1.8,4,4,4c1.5,0,2.7-0.7,3.3-1.9l1.3,0.7C34,38.1,32.5,42.2,29.9,45.8z
    //         M31.6,32.1c-0.4,0.5-0.9,0.9-1.7,0.9c-1.1,0-2-0.9-2-2V30L31.6,32.1z M28.3,27.9c0.4-0.5,0.9-0.9,1.6-0.9c1.1,0,2,0.9,2,2V30
    //         L28.3,27.9z M34.8,31.7L34,31.2c0-0.1,0-0.1,0-0.2v-1.9c0-2.2-1.8-4-4-4c-1.4,0-2.6,0.7-3.3,1.8l-1.3-0.7c0.6-4.3,2.2-8.3,4.6-12
    //         c3.2,4.6,4.9,10.1,4.9,15.8C34.8,30.6,34.8,31.1,34.8,31.7z"/>
    // </svg>,
    'Wildlife': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M38.3,19.1c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8C38,19.9,38.3,19.6,38.3,19.1z"/>
        <path d="M48.6,22L46,19.4h-3.7v-0.3c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6v4.2c-0.4,0-0.8,0-1.2,0.1
            c-0.4,0.1-0.8,0.2-1.1,0.3c-0.3,0.1-0.7,0.3-1,0.5L10,35.1h21.3c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.2,1.1-0.3
            c0.3-0.1,0.7-0.3,1-0.5c0.3-0.2,0.6-0.5,0.9-0.7c0.3-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.4-0.7,0.5-1c0.2-0.4,0.3-0.7,0.3-1.1
            c0.1-0.4,0.1-0.8,0.1-1.2s0-0.8-0.1-1.2c-0.1-0.4-0.2-0.8-0.3-1.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.6-0.7-0.9
            c-0.3-0.3-0.6-0.5-0.9-0.7c-0.2-0.2-0.5-0.3-0.8-0.4v-4.8c0-1.6,1.3-3,3-3c1.6,0,3,1.3,3,3v10.8c0,2-0.8,3.9-2.2,5.4
            c-1.4,1.4-3.3,2.2-5.4,2.2H9.7V40h16.6v6.2h2.6V40h2.4v6.2h6.1v-2.6h-3.5v-3.8c2.1-0.3,4-1.3,5.5-2.8c1.9-1.9,3-4.5,3-7.2V22H48.6z
            M34.3,27.9c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0.1,0.4,0.1,0.7s0,0.5-0.1,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.6
            c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0.1-0.7,0.1
            h-12l10.1-6.1l0.1,0c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.4-0.1,0.9-0.1,1.3,0c0.2,0,0.4,0.1,0.6,0.2
            c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.3,0.3,0.4,0.5C34.2,27.5,34.3,27.7,34.3,27.9z"/>
    </svg>,
    'Road Trip': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M45.9,41.3l-6.8,4v-7l-0.6,0.3l-0.8-0.5v7L30.4,41v-7l-0.8-0.3l-0.8,0.5v7l-7.3,4.1v-7l-0.8,0.5l-0.6-0.5v7l-6.8-3.8V20.9
        l6.8,3.8v7.1l0.8,0.5l0.8-0.5v-7.1l7.3-4.1v7.1l0.8-0.5l0.6,0.3v-7l8.1,4.6l0.3-0.2c-0.6-1.1-1.4-2.4-2.1-3.8l-7.1-4l-8.7,5.1
        l-10.1-5.7v26.3l10,5.7l8.7-5.1l8.7,5.1l10-5.7V21.3c-0.8,1.6-1.7,3.3-2.5,4.6v15.4H45.9z"/>
        <polygon points="37.2,34.2 36.5,35.1 38.4,36.2 40.3,35.1 39.7,34.2 38.4,34.8 "/>
        <path d="M40.3,33.2c0,1.1,1,2.1,2.1,2.1c1.1,0,2.1-1,2.1-2.1c0-1.1-1-2.1-2.1-2.1C41.3,31.2,40.3,32.1,40.3,33.2z"/>
        <path d="M47.8,16.4c0-2.9-2.4-5.2-5.2-5.2c-2.9,0-5.2,2.4-5.2,5.2c0,3.2,5.2,10.8,5.2,10.8S47.8,19.6,47.8,16.4z"/>
        <rect x="33.3" y="31.2" transform="matrix(0.4972 -0.8676 0.8676 0.4972 -11.5657 46.0794)" width="1.3" height="3.6"/>
        <path d="M14.5,33.2c0,1.1,1,2.1,2.1,2.1s2.1-1,2.1-2.1c0-1.1-1-2.1-2.1-2.1S14.5,32.1,14.5,33.2z"/>
        <polygon points="19.6,34.2 18.9,35.1 20.8,36.2 22.6,35.1 22,34.2 20.8,34.8 "/>
        <rect x="23.4" y="32.4" transform="matrix(0.8679 -0.4968 0.4968 0.8679 -13.0917 16.9021)" width="3.6" height="1.3"/>
        <polygon points="27.8,30.9 28.4,32 29.6,31.2 30.8,32 31.5,30.9 29.6,29.7 "/>
    </svg>,
    'Biking': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M35.4,18c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9S33.8,18,35.4,18z M44.3,30.5c-4.8,0-8.7,3.9-8.7,8.7
        s3.9,8.7,8.7,8.7s8.7-3.9,8.7-8.7S49.1,30.5,44.3,30.5z M44.3,45.2c-3.3,0-5.9-2.7-5.9-5.9c0-3.3,2.7-5.9,5.9-5.9s5.9,2.7,5.9,5.9
        S47.6,45.2,44.3,45.2z M35.4,27.2h8.3v-4.1h-6.6l-6.6-6.6L19.8,27.1l8.1,8.1v9.9h4.1V33.5l-6.4-6.4l4.7-4.7L35.4,27.2z M15.7,30.5
        c-4.8,0-8.7,3.9-8.7,8.7s3.9,8.7,8.7,8.7s8.7-3.9,8.7-8.7S20.5,30.5,15.7,30.5z M15.7,45.2c-3.3,0-5.9-2.7-5.9-5.9
        c0-3.3,2.7-5.9,5.9-5.9s5.9,2.7,5.9,5.9C21.7,42.5,19,45.2,15.7,45.2z"/>
    </svg>,
    'Excursions': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path id="car" d="M6,34.1h2.9v-11H6V34.1z M51.7,35.9v-6.9c0-1.5-1.2-2.9-2.9-2.9h-8.5l-3.2-8.2l-10.2-1.5h-16V35l3.7,3.7h0.2
        c0.5,3,3.4,5.2,6.6,4.7c2.4-0.5,4.3-2.3,4.7-4.7h12.5c0.5,3,3.4,5.2,6.6,4.7c2.4-0.5,4.3-2.3,4.7-4.7H54v-2.9L51.7,35.9z M20.3,40.6
        c-1.5,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9c1.5,0,2.9,1.2,2.9,2.9l0,0C23.2,39.4,21.8,40.6,20.3,40.6L20.3,40.6z M25.2,26.3
        H13.8v-7h11.4V26.3z M28.1,26.3v-6.7l7,1.1l2.3,5.8L28.1,26.3z M44.1,40.6c-1.5,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9
        c1.5,0,2.9,1.2,2.9,2.9l0,0C46.8,39.4,45.6,40.6,44.1,40.6L44.1,40.6z"/>
    </svg>,
    'Zodiac': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M33.8,23.7c1.1,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1c-1.1,0-2.1,1-2.1,2.1C31.7,22.8,32.7,23.7,33.8,23.7z M26.5,23.7
            c1.1,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1s-2.1,1-2.1,2.1C24.4,22.8,25.4,23.7,26.5,23.7z M53.4,29.5c-1.3-2.6-4.4-3.7-7-2.4
            c-1.9,1-4.9,2-6,2h-2.7l-1.8-4.8h-0.1H32v4.8h-1.6l-1.8-4.8h-0.1h-3.8v4.8h-1.6l-1.8-4.8h-0.1h-3.8v4.8h-6l-5.6,5.2l5.6,5.2h29
            c4.1,0,9.2-2.3,10.7-3c1.2-0.6,2.2-1.7,2.7-3C54.1,32.2,54.1,30.8,53.4,29.5z M51.2,32.7c-0.3,0.7-0.7,1.2-1.3,1.6
            c-2.6,1.3-6.7,2.8-9.5,2.8h-28l-2.9-2.6l2.9-2.6h28c1.9,0,5.4-1.4,7.2-2.3c0.6-0.3,1.4-0.3,2-0.1c0.7,0.3,1.2,0.7,1.6,1.3
            C51.4,31.3,51.5,32,51.2,32.7z M19.2,23.7c1.1,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1s-2.1,1-2.1,2.1C17.1,22.8,18.1,23.7,19.2,23.7z"/>
    </svg>,
    'Multi': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" >
        <polygon points="4,39.7 6.5,33 9.1,39.7 11,39.7 7.5,30.6 8.7,27.5 9.5,29.5 12.5,29.5 12.5,39.7 13.7,39.7 13.7,29.5 14.3,29.5
            14.3,27.8 13.7,27.8 13.7,24 12.5,24 12.5,27.8 10.7,27.8 9.6,25 9.6,25 9.6,25 9.6,25 9.6,25 8,24.4 2.1,39.7 	"/>
        <circle cx="9.6" cy="22.7" r="1.3"/>
        <path d="M7.3,22.8L4,21.5l-2.8,7.2L4.5,30L7.3,22.8z M2.7,28.1l2-5l1.1,0.4l-2,5L2.7,28.1z"/>
        <path d="M36.1,33.8l-1.6-0.9c-0.7-0.4-1.5-0.3-2,0.2L31,32.2c0-0.5,0.1-1.1,0.1-1.6c0-3.9-1.2-7.6-3.6-10.7L27,19.3l-0.5,0.6
        c-1.8,2.3-2.9,5-3.4,7.8L22.1,27c0.1-0.7-0.2-1.4-0.8-1.8l-1.6-0.9c-0.8-0.5-1.9-0.2-2.4,0.6l0,0c-0.5,0.8-0.2,1.9,0.6,2.4l1.6,0.9
        c0.7,0.4,1.5,0.3,2-0.2l1.5,0.9c0,0.5-0.1,1.1-0.1,1.6c0,3.9,1.2,7.6,3.6,10.7l0.5,0.6l0.5-0.6c1.8-2.3,2.9-5,3.4-7.8l1.1,0.6
        c-0.1,0.7,0.2,1.4,0.8,1.8l1.6,0.9c0.8,0.5,1.9,0.2,2.4-0.6l0,0C37.2,35.4,36.9,34.3,36.1,33.8z M27,39.9c-1.9-2.7-2.9-5.9-2.9-9.3
        c0-0.3,0-0.7,0-1l0.5,0.3c0,0,0,0.1,0,0.1v1.1c0,1.3,1.1,2.4,2.4,2.4c0.8,0,1.6-0.4,2-1.1l0.8,0.4C29.4,35.4,28.4,37.8,27,39.9z
        M27.9,31.8c-0.2,0.3-0.6,0.5-1,0.5c-0.7,0-1.2-0.5-1.2-1.2v-0.6L27.9,31.8z M26,29.3c0.2-0.3,0.6-0.5,1-0.5c0.7,0,1.2,0.5,1.2,1.2
        v0.5L26,29.3z M29.8,31.5l-0.5-0.3c0,0,0-0.1,0-0.1V30c0-1.3-1.1-2.4-2.4-2.4c-0.8,0-1.6,0.4-2,1.1l-0.8-0.4c0.3-2.5,1.3-4.9,2.7-7
        c1.9,2.7,2.9,5.9,2.9,9.3C29.9,30.9,29.9,31.2,29.8,31.5z"/>
        <path d="M47.4,22.9c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C46.3,22.4,46.8,22.9,47.4,22.9z"/>
        <path d="M58.6,26.5l-2.5-2.3v-1.2l-4.7,4.4l-2.8-2.1v-1.8h-2.4v4.8h-4.2v0h-0.3c-1.3,0-2.3,1-2.3,2.3v2.8h1.5v-2.8
            c0-0.4,0.4-0.8,0.8-0.8h0.3V33l-0.7,0.7v5.6H43v-4.8l1-1L43.7,34l2,5.3h2l-1.9-5.1l0.4-0.9h4.3v6h1.8V33l3.3-5.6l2.1,0.6
            C58.6,28.3,59.3,27.1,58.6,26.5z M50.4,28.2h-1.9v-1.5L50.4,28.2L50.4,28.2z"/>
    </svg>,
    'Peninsula': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M45.9,41.3l-6.8,4v-7l-0.6,0.3l-0.8-0.5v7L30.4,41v-7l-0.8-0.3l-0.8,0.5v7l-7.3,4.1v-7l-0.8,0.5l-0.6-0.5v7l-6.8-3.8V20.9
        l6.8,3.8v7.1l0.8,0.5l0.8-0.5v-7.1l7.3-4.1v7.1l0.8-0.5l0.6,0.3v-7l8.1,4.6l0.3-0.2c-0.6-1.1-1.4-2.4-2.1-3.8l-7.1-4l-8.7,5.1
        l-10.1-5.7v26.3l10,5.7l8.7-5.1l8.7,5.1l10-5.7V21.3c-0.8,1.6-1.7,3.3-2.5,4.6v15.4H45.9z"/>
    <polygon points="37.2,34.2 36.5,35.1 38.4,36.2 40.3,35.1 39.7,34.2 38.4,34.8 "/>
    <path d="M40.3,33.2c0,1.1,1,2.1,2.1,2.1c1.1,0,2.1-1,2.1-2.1c0-1.1-1-2.1-2.1-2.1C41.3,31.2,40.3,32.1,40.3,33.2z"/>
    <path d="M47.8,16.4c0-2.9-2.4-5.2-5.2-5.2c-2.9,0-5.2,2.4-5.2,5.2c0,3.2,5.2,10.8,5.2,10.8S47.8,19.6,47.8,16.4z"/>
    <rect x="33.3" y="31.2" transform="matrix(0.4972 -0.8676 0.8676 0.4972 -11.5657 46.0794)" width="1.3" height="3.6"/>
    <path d="M14.5,33.2c0,1.1,1,2.1,2.1,2.1s2.1-1,2.1-2.1c0-1.1-1-2.1-2.1-2.1S14.5,32.1,14.5,33.2z"/>
    <polygon points="19.6,34.2 18.9,35.1 20.8,36.2 22.6,35.1 22,34.2 20.8,34.8 "/>
    <rect x="23.4" y="32.4" transform="matrix(0.8679 -0.4968 0.4968 0.8679 -13.0917 16.9021)" width="3.6" height="1.3"/>
    <polygon points="27.8,30.9 28.4,32 29.6,31.2 30.8,32 31.5,30.9 29.6,29.7 "/>
    </svg>,
    'Falklands South Georgia': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M37.6,43.9c1.9-2.1,2.7-5,2.4-7.8l-2.2-16.6c-0.1-0.8-0.3-1.5-0.6-2.2l1.5-3.8l-4.3,0.6c-1.2-0.8-2.6-1.3-4.2-1.3
        c-3.8,0-7,2.8-7.5,6.6l-2.2,16.6c-0.4,2.8,0.5,5.7,2.4,7.8c1.9,2.1,4.6,3.4,7.4,3.4h9.6v-2.7H37C37.2,44.4,37.4,44.2,37.6,43.9z
         M31.9,15.9c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1S31.3,15.9,31.9,15.9z M30.2,44.6
        c-1.3,0-2.5-0.3-3.5-0.9c2.9-1.1,4.9-3.8,4.9-7.1l0-9.6c0-2.3,1.6-4.2,3.8-4.7l1.9,14.3c0.3,2-0.3,4.1-1.7,5.6
        C34.2,43.7,32.2,44.6,30.2,44.6z"/>
    </svg>,
    'Polar Circle': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M18.5,23.5l14-4.6l-3.8,11.7h2.8L35.3,19l5.8,4.2l2.4,7.4h2.8l-2.9-9.1l-8.6-6.3l-18.5,6l-3,9.3h2.8L18.5,23.5z M47.4,33.2
        h-2.5c-0.7,1.7-2.4,2.8-4.2,2.8c-1.8,0-3.5-1.1-4.2-2.8h-2.5c-0.7,1.7-2.4,2.8-4.2,2.8s-3.5-1.1-4.2-2.8h-2.5
        C22.5,34.9,20.8,36,19,36s-3.5-1.1-4.2-2.8h-2.5C11.6,34.9,10,36,8.1,36v2.7c2.1,0,4.1-0.9,5.4-2.4c1.4,1.5,3.3,2.4,5.4,2.4
        s4.1-0.9,5.4-2.4c1.4,1.5,3.3,2.4,5.4,2.4s4.1-0.9,5.4-2.4c1.4,1.5,3.3,2.4,5.4,2.4c2.1,0,4.1-0.9,5.4-2.4c1.4,1.5,3.3,2.4,5.4,2.4
        V36C49.8,36,48.1,34.9,47.4,33.2z M47.4,39.9h-2.5c-0.7,1.7-2.4,2.8-4.2,2.8c-1.8,0-3.5-1.1-4.2-2.8h-2.5c-0.7,1.7-2.4,2.8-4.2,2.8
        s-3.5-1.1-4.2-2.8h-2.5c-0.7,1.7-2.4,2.8-4.2,2.8s-3.5-1.1-4.2-2.8h-2.5c-0.7,1.7-2.4,2.8-4.2,2.8v2.7c2.1,0,4.1-0.9,5.4-2.4
        c1.4,1.5,3.3,2.4,5.4,2.4s4.1-0.9,5.4-2.4c1.4,1.5,3.3,2.4,5.4,2.4s4.1-0.9,5.4-2.4c1.4,1.5,3.3,2.4,5.4,2.4c2.1,0,4.1-0.9,5.4-2.4
        c1.4,1.5,3.3,2.4,5.4,2.4v-2.7C49.8,42.6,48.1,41.5,47.4,39.9z"/>
    </svg>,
    'Ross Sea': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path id="ship" d="M45.1,31.3l-3.9-6.9h-2.7L35.4,19h-2.8l3.1,5.5h-2.7L30,19.2h-5.1l-3.1,5.3h-2.2l-3.9,6.9h-4.2l-5.3,9.2h42.6
        l5.3-9.2H45.1z M26.2,21.5h2.2l1.6,2.7h-5.3L26.2,21.5z M18.5,31.3l2.5-4.4h18.9l2.5,4.4H18.5z"/>
    </svg>,
    'Weddell Sea': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M33.8,23.7c1.1,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1c-1.1,0-2.1,1-2.1,2.1C31.7,22.8,32.7,23.7,33.8,23.7z M26.5,23.7
        c1.1,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1s-2.1,1-2.1,2.1C24.4,22.8,25.4,23.7,26.5,23.7z M53.4,29.5c-1.3-2.6-4.4-3.7-7-2.4
        c-1.9,1-4.9,2-6,2h-2.7l-1.8-4.8h-0.1H32v4.8h-1.6l-1.8-4.8h-0.1h-3.8v4.8h-1.6l-1.8-4.8h-0.1h-3.8v4.8h-6l-5.6,5.2l5.6,5.2h29
        c4.1,0,9.2-2.3,10.7-3c1.2-0.6,2.2-1.7,2.7-3C54.1,32.2,54.1,30.8,53.4,29.5z M51.2,32.7c-0.3,0.7-0.7,1.2-1.3,1.6
        c-2.6,1.3-6.7,2.8-9.5,2.8h-28l-2.9-2.6l2.9-2.6h28c1.9,0,5.4-1.4,7.2-2.3c0.6-0.3,1.4-0.3,2-0.1c0.7,0.3,1.2,0.7,1.6,1.3
        C51.4,31.3,51.5,32,51.2,32.7z M19.2,23.7c1.1,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1s-2.1,1-2.1,2.1C17.1,22.8,18.1,23.7,19.2,23.7z"/>
    </svg>,
    'Camp': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <polygon points="31.8,19.6 35.2,13.6 32.5,12 30,16.4 27.5,12 24.8,13.6 28.2,19.6 11.8,48 24,48 30,37.6 36,48 48.2,48 "/>
    </svg>
    ,
    'Kayaking': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M45.4,35.5L42.8,34c-1.1-0.7-2.5-0.5-3.4,0.3l-2.6-1.5c0.1-0.9,0.1-1.8,0.1-2.8c0-6.6-2.1-12.8-6.1-18.1l-0.8-1l-0.9,1
        c-3,3.9-4.9,8.4-5.7,13.2l-1.8-1c0.2-1.2-0.3-2.5-1.5-3.1l-2.7-1.5c-1.5-0.8-3.3-0.4-4.1,1.1l0,0c-0.8,1.5-0.4,3.3,1.1,4.1l2.7,1.5
        c1.1,0.7,2.5,0.5,3.4-0.3l2.5,1.5c-0.1,0.9-0.1,1.8-0.1,2.8c0,6.6,2.1,12.8,6.1,18.1l0.8,1l0.9-1c3-3.9,4.9-8.4,5.7-13.2l1.9,1.1
        c-0.2,1.2,0.3,2.5,1.5,3.1l2.7,1.5c1.5,0.8,3.2,0.4,4.1-1.1l0,0C47.3,38.1,46.8,36.3,45.4,35.5z M29.9,45.8C26.7,41.2,25,35.7,25,30
        c0-0.6,0-1.1,0.1-1.7l0.9,0.5c0,0.1,0,0.1,0,0.2v1.9c0,2.2,1.8,4,4,4c1.5,0,2.7-0.7,3.3-1.9l1.3,0.7C34,38.1,32.5,42.2,29.9,45.8z
         M31.6,32.1c-0.4,0.5-0.9,0.9-1.7,0.9c-1.1,0-2-0.9-2-2V30L31.6,32.1z M28.3,27.9c0.4-0.5,0.9-0.9,1.6-0.9c1.1,0,2,0.9,2,2V30
        L28.3,27.9z M34.8,31.7L34,31.2c0-0.1,0-0.1,0-0.2v-1.9c0-2.2-1.8-4-4-4c-1.4,0-2.6,0.7-3.3,1.8l-1.3-0.7c0.6-4.3,2.2-8.3,4.6-12
        c3.2,4.6,4.9,10.1,4.9,15.8C34.8,30.6,34.8,31.1,34.8,31.7z"/>
    </svg>,
    'Mountaineering': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
	<circle cx="30.8" cy="14" r="2.5"/>
	<path d="M26.2,14.2l-6.8-2.6l-5.8,14.7l6.8,2.6L26.2,14.2z M16.9,24.9l4-10.2l2.3,0.9l-4,10.1L16.9,24.9z"/>
	<polygon points="15.6,48.5 19.4,48.5 25.2,33.5 30.7,35.6 25.7,48.5 29.5,48.5 35.4,33.5 26.5,30.1 29.6,22 38.2,25.3 37.5,27.1
		39.7,28 40.4,26.1 41,26.4 42.2,23 41.7,22.8 43.8,17.6 48.1,19.2 42.7,13.5 41.5,16.6 41.5,16.6 39.4,21.9 27.6,17.3 26.2,20.7
		26.2,20.7 	"/>
    </svg>,
    'Diving': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M40.9,39c-0.3,1.1,0.4,2.3,1.5,2.5c1.1,0.3,2.3-0.4,2.5-1.5c0.3-1.1-0.4-2.3-1.5-2.5C42.2,37.3,41.1,37.9,40.9,39z"/>
    <path d="M40.9,36.5c0.8-0.2,1.6-0.6,2-1.3c0.5-0.7,0.6-1.5,0.5-2.4c-0.2-0.8-0.6-1.6-1.3-2l-8.7-5.9l-3.6,5.2l8.7,5.9
        C39.2,36.5,40.1,36.6,40.9,36.5z M41.1,33.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.3-0.7,0.4-1.1,0.2l-3.3-2.3l0.9-1.2l3.3,2.2
        C40.9,32.9,41,33.1,41.1,33.2z M34,28.1l1.5,1l-0.9,1.2l-1.4-1L34,28.1z"/>
    <polygon points="25.7,29.1 24.4,22.2 20.2,19.4 22.5,31.2 35.9,40.2 37.8,49.9 41.3,49.2 39.2,38 "/>
    <polygon points="23,15.3 14.8,9.8 13.4,11.7 23.7,18.7 "/>
    </svg>,
    'Snorkelling': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <polygon points="27.5,37.6 21.5,35.6 17.3,37.7 27.6,41.2 40.9,34.6 49.4,37.5 50.4,34.4 40.6,31.1 "/>
    <polygon points="15.4,33.6 7.2,37.6 8.2,39.6 18.4,34.6 "/>
    <path d="M8.6,22h0.3c0.7,1.3,2,2,3.4,2s2.7-0.8,3.4-2H16c0.7,1.3,2,2,3.4,2c1.4,0,2.7-0.8,3.4-2h0.3c0.7,1.3,2,2,3.4,2
        c1.4,0,2.7-0.8,3.4-2h0.3c0.7,1.3,2,2,3.4,2s2.7-0.8,3.4-2h0.3c0.7,1.3,2,2,3.4,2c1.4,0,2.7-0.8,3.4-2h0.3c0.4,0.9,1.3,1.5,2.2,1.8
        v-1.3c-0.5-0.3-1-0.7-1.3-1.3l-0.2-0.4h-1.8l-0.2,0.4c-0.4,1-1.4,1.6-2.5,1.6s-2.1-0.6-2.5-1.6l-0.2-0.4h-1.8l-0.2,0.4
        c-0.4,1-1.4,1.6-2.5,1.6c-1.1,0-2.1-0.6-2.5-1.6v-0.4h-1.8l-0.2,0.4c-0.4,1-1.4,1.6-2.5,1.6c-1.1,0-2.1-0.6-2.5-1.6l-0.2-0.4h-1.8
        v0.4c-0.4,1-1.4,1.6-2.5,1.6c-1.1,0-2.1-0.6-2.5-1.6l-0.2-0.4h-1.8l-0.2,0.4c-0.4,1-1.4,1.6-2.5,1.6s-2-0.6-2.4-1.6l-0.2-0.4H7.8
        l-0.2,0.4c-0.4,1-1.4,1.6-2.5,1.6V24C6.7,24,7.9,23.3,8.6,22z"/>
    <path d="M52.3,21.2l-0.2-0.4h-1.8l-0.2,0.4c-0.2,0.4-0.5,0.9-0.9,1.2v1.3c0.7-0.4,1.4-0.9,1.8-1.6h0.3c0.7,1.2,1.9,2,3.3,2v-1.2
        C53.7,22.8,52.8,22.2,52.3,21.2z"/>
    <path d="M45.9,28.5L45.9,28.5c1.3,0,2.4-1.1,2.4-2.4v-4.9h-0.6v4.9c0,1-0.8,1.8-1.8,1.8C46,28,46,28.2,45.9,28.5z"/>
    <path d="M46,26.8l-0.8-1c-0.1-0.2-0.4-0.2-0.5-0.1l-0.5,0.4c-0.8-0.3-1.6-0.3-2.3,0.2c-1.1,0.8-1.4,2.2-0.6,3.3
        c0.8,1.1,2.2,1.4,3.3,0.6c0.9-0.6,1.3-1.7,0.9-2.7l0.4-0.3C46.1,27.2,46.1,26.9,46,26.8z"/>
    </svg>,
    'Snowshoeing': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path class="st0" d="M36,27.6c1.8,0.5,3.6,0.8,5.4,0.8h2.5c4.3,0,7.8-3.5,7.8-7.8s-3.5-7.8-7.8-7.8h-2.5c-1.8,0-3.7,0.3-5.4,0.8
        l-19.3,5.6H8.2V22h8.5L36,27.6z M44.3,15.5c2.1,0.1,3.9,1.6,4.4,3.6h-4.4V15.5z M44.3,22h4.4c-0.6,2-2.3,3.4-4.4,3.6V22z M37.6,16
        c1.3-0.3,2.6-0.5,3.9-0.5h0v3.6h-3.9V16z M37.6,22h3.9v3.6h0c-1.3,0-2.6-0.2-3.9-0.5V22z M34.7,16.8v2.3h-7.9L34.7,16.8z M34.7,22
        v2.3L26.8,22H34.7z M43.9,31.6h-2.5c-1.8,0-3.7,0.3-5.4,0.8L16.7,38H8.2v2.8h8.5L36,46.5c1.8,0.5,3.6,0.8,5.4,0.8h2.5
        c4.3,0,7.8-3.5,7.8-7.8S48.3,31.6,43.9,31.6z M34.7,43.2l-7.9-2.3h7.9V43.2z M34.7,38h-7.9l7.9-2.3V38z M41.4,44.5L41.4,44.5
        c-1.3,0-2.6-0.2-3.9-0.5v-3.1h3.9V44.5z M41.4,38h-3.9v-3.1c1.3-0.3,2.6-0.5,3.9-0.5h0V38z M44.3,44.5v-3.6h4.4
        C48.1,42.8,46.4,44.3,44.3,44.5z M44.3,38v-3.6c2.1,0.1,3.9,1.6,4.4,3.6H44.3z"/>
    </svg>,
    'Paddleboarding': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path class="st0" d="M29,11.6c1,0,1.8-0.8,1.8-1.8S30,8.1,29,8.1c-1,0-1.8,0.8-1.8,1.8S28,11.6,29,11.6z M13,39h15.7
        c0,0.5,0.3,1,0.8,1.2c0.2,0.1,0.3,0.1,0.5,0.1c0.5,0,1-0.3,1.2-0.8l0.2-0.4H47c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3H32.4
        l3.8-9.9h0l4.7-12.3l-2.5-0.9l-1.1,3l-9.2-3.5l0,0l-1.1-0.5l-3.4,8.4l4.4,5L24.7,34h2.8l3.5-8.8l-4.4-5l1.8-4.5l7.9,3.1l-2.1,5.6
        l0,0l-4.6,12.1H13c-0.7,0-1.3,0.6-1.3,1.3C11.7,38.4,12.3,39,13,39z M44.9,41.5h-2.4c-0.6,1.3-1.9,2.2-3.4,2.2
        c-1.5,0-2.8-0.9-3.4-2.2h-2.4c-0.6,1.3-1.9,2.2-3.4,2.2s-2.8-0.9-3.4-2.2h-2.4c-0.6,1.3-1.9,2.2-3.4,2.2c-1.5,0-2.8-0.9-3.4-2.2
        l-2.4,0c-0.6,1.3-1.9,2.2-3.4,2.2v2.6c1.8,0,3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2c1.8,0,3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2
        s3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2c1.8,0,3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2v-2.6C46.8,43.7,45.5,42.8,44.9,41.5z M44.9,47.1h-2.4
        c-0.6,1.3-1.9,2.2-3.4,2.2c-1.5,0-2.8-0.9-3.4-2.2h-2.4c-0.6,1.3-1.9,2.2-3.4,2.2s-2.8-0.9-3.4-2.2h-2.4c-0.6,1.3-1.9,2.2-3.4,2.2
        c-1.5,0-2.8-0.9-3.4-2.2l-2.4,0c-0.6,1.3-1.9,2.2-3.4,2.2v2.6c1.8,0,3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2c1.8,0,3.4-0.7,4.6-2
        c1.2,1.2,2.8,2,4.6,2s3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2s3.4-0.7,4.6-2c1.2,1.2,2.8,2,4.6,2v-2.6C46.8,49.3,45.5,48.4,44.9,47.1z"/>
    </svg>,
    'Cross Country Skiing': <svg className="self-center fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M36.1,16.2c1.2,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C34,15.3,34.9,16.2,36.1,16.2z M27.7,25.2l-0.9,0.3
        l1.3-3.4l4.4-1.6L23.7,43h2.9l4.7-12.4l3.4,3.9V43h2.7l0.1-9.6l-5-5.7l2.6-6.8l4.6,5.4h4.6L38.1,43H41l7.5-20l-2.5-1l-0.6,1.6H41
        L36.3,18l-2.5-1l-7.8,3l-2.4,6.7L7.3,32.6v2.9l0,0l21.3-7.7L27.7,25.2z M50,43.1c0,1.3-1,2.2-2.2,2.2h-1.7c-0.4,1.1-1.1,2-1.8,2.7
        h3.6c2.7,0,4.9-2.2,4.9-4.9H50z M42,43.1c0,1.3-1,2.2-2.2,2.2H9.1V48h30.7c2.7,0,4.9-2.2,4.9-4.9H42z"/>
    </svg>,


    'Right-Chevron': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"
        fill="currentColor" className="text-gray-400 ml-auto w-6 h-6 mr-2 align-text-bottom"
    >
        <path d="M40.1,31.4L24.5,46.9c-0.8,0.8-2,0.8-2.7,0L20,45.1c-0.8-0.8-0.8-2,0-2.7L32.3,30L20,17.6c-0.7-0.8-0.7-2,0-2.7l1.8-1.8
            c0.8-0.8,2-0.8,2.7,0l15.6,15.5C40.8,29.4,40.8,30.6,40.1,31.4L40.1,31.4z"/>
    </svg>,
    'Down-Chevron': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"
        fill="currentColor" className="text-gray-400 ml-auto w-6 h-6 mr-2 align-text-bottom"
    >
        <path d="M28.6,40.1L13.1,24.5c-0.8-0.8-0.8-2,0-2.7l1.8-1.8c0.8-0.8,2-0.8,2.7,0L30,32.3l12.4-12.3c0.8-0.7,2-0.7,2.7,0l1.8,1.8
            c0.8,0.8,0.8,2,0,2.7L31.4,40.1C30.6,40.8,29.4,40.8,28.6,40.1L28.6,40.1z"/>
    </svg>,
    'Basic': <svg className="fill-current h-10 w-10 mr-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M53.3,43c-0.9,0-1.7,0-2.6,0c0-2.1,0-4.2,0-6.4c-6.1,0-12.2,0-18.3,0c0-4.5,0-8.9,0-13.4c0.2,0,0.4,0,0.5,0
            c4.9,0,9.8,0,14.8,0c5.2,0,5.6,2.2,5.6,6.8c0,4,0,8,0,12C53.3,42.3,53.3,42.6,53.3,43z"/>
        <path d="M7.7,15c0.9,0,1.7,0,2.6,0c0,5.3,0,10.5,0,15.8c6.8,0,13.6,0,20.3,0c0,2,0,3.9,0,5.8c-6.8,0-13.5,0-20.3,0
            c0,2.2,0,4.3,0,6.4c-0.9,0-1.7,0-2.6,0C7.7,33.7,7.7,24.4,7.7,15z"/>
        <path d="M12.6,22.8c0-2.5,1.7-4.6,3.8-4.6c2.1,0,3.8,2.1,3.8,4.6c0,2.5-1.8,4.6-3.9,4.6C14.3,27.4,12.6,25.3,12.6,22.8z"/>
        <path d="M30.7,28.9c-3.5,0-6.9,0-10.4,0c0.1-2.2,1.7-4.5,3.6-5.2c0.5-0.2,1.1-0.3,1.6-0.3c1.6,0,3.2,0,4.9,0c0.1,0,0.2,0,0.3,0
            C30.7,25.1,30.7,27,30.7,28.9z"/>
    </svg>,
    'Expedition': <svg className="fill-current h-10 w-10 mr-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M49.6,40.9l-1.5-1.9c-0.9,0.7-1.8,1.4-2.8,1.9l-8.4-15H39v1.4h2.4V22H39v1.4h-3.5L33,18.8c0.9-0.9,1.4-2.1,1.4-3.4
        c0-2.6-2.1-4.8-4.8-4.8c-2.6,0-4.8,2.1-4.8,4.8c0,1.3,0.5,2.5,1.4,3.4L13.9,40.9c-1-0.6-1.9-1.2-2.8-1.9l-1.5,1.9
        c4.6,3.6,10,5.9,15.8,6.6l-0.2,1.2c1.4,0.2,2.9,0.3,4.4,0.3c1.5,0,2.9-0.1,4.4-0.3l-0.2-1.2C39.6,46.7,45,44.5,49.6,40.9z
         M36.5,30.1c-1.8,0.8-3.7,1.3-5.7,1.5v-5.7h3.3L36.5,30.1z M30.9,20l1.9,3.4h-2L30.9,20C30.9,20,30.9,20,30.9,20z M29.6,13.1
        c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4C27.3,14.1,28.3,13.1,29.6,13.1z M28.4,44.1
        c-0.9,0-1.7-0.1-2.5-0.2l-0.2,1.2c-3.4-0.4-6.7-1.4-9.7-3l5.5-9.9c2.1,1,4.5,1.6,6.9,1.8V44.1z M22.7,30l5.6-10c0,0,0.1,0,0.1,0
        v11.5C26.5,31.4,24.5,30.9,22.7,30z M33.4,43.9c-0.8,0.1-1.7,0.2-2.5,0.2V34c2.4-0.2,4.7-0.8,6.8-1.8l5.5,9.9c-3,1.5-6.3,2.6-9.7,3
        L33.4,43.9z"/>
    </svg>,
    'Luxury': <svg className="fill-current h-10 w-10 mr-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M28,32.6v10.8h2.3V32.6L43,19.9h-6.5l6.4-6.4l-1.6-1.6l-8.1,8.1h-18L28,32.6z M37.4,22.3l-2.5,2.5h-3.2l2.5-2.5H37.4z
            M28.4,24.8h-5l-2.5-2.5h10L28.4,24.8z"/>
        <rect x="20.9" y="45.7" width="16.5" height="2.3"/>
    </svg>,
    'Comfort & Adventure': <svg className="fill-current h-10 w-10 mr-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M38.2,26.4l1.4-2.3v-4.3H31v-4.8h-2.1v4.8h-2.3v-4.8h-2.1v4.8l0,3.3h-8.2v5.4H6.6v7c0.4,0.2,0.9,0.3,1.3,0.3
        c1.1,0,2.1-0.6,2.6-1.6l0.2-0.5h3.3l0.2,0.5c0.5,1,1.5,1.6,2.6,1.6c1.1,0,2.1-0.6,2.6-1.6l0.2-0.5h3.3l0.2,0.5
        c0.4,1,1.4,1.6,2.5,1.6c1.1,0,2.1-0.6,2.5-1.6l0.2-0.5h3.3l0.2,0.5c0.4,1,1.4,1.6,2.6,1.6c1.1,0,2.1-0.6,2.6-1.6l0.2-0.5h3.3
        l0.3,0.5c0.3,0.6,0.8,1.1,1.4,1.4l5.8-9.2H38.2z M37.3,23.5l-1.7,2.9h-4.7l-1.2,2.1H18.5v-3.3h8.2v-3.3h10.6V23.5z"/>
    <path d="M53.6,36.4v2.3c-0.5,0.1-1,0.2-1.5,0.2c-1.7,0-3.3-0.7-4.4-2c-1.1,1.2-2.7,2-4.4,2c-1.7,0-3.3-0.7-4.4-2
        c-1.1,1.2-2.7,2-4.4,2c-1.7,0-3.3-0.7-4.4-2c-1.1,1.2-2.7,1.9-4.4,1.9v0c0,0,0,0,0,0s0,0,0,0v0c-1.7,0-3.3-0.7-4.4-1.9
        c-1.1,1.2-2.7,2-4.4,2c-1.7,0-3.3-0.7-4.4-2c-1.1,1.2-2.7,2-4.4,2c-0.8,0-1.5-0.2-2.2-0.4V36c0.6,0.5,1.4,0.7,2.2,0.7
        c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2
        c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2
        C52.6,36.7,53.1,36.6,53.6,36.4z"/>
    <path d="M53.6,42.2v2.3c-0.5,0.1-1,0.2-1.5,0.2c-1.7,0-3.3-0.7-4.4-1.9c-1.1,1.2-2.7,1.9-4.4,1.9c-1.7,0-3.3-0.7-4.4-1.9
        c-1.1,1.2-2.7,1.9-4.4,1.9c-1.7,0-3.3-0.7-4.4-1.9c-1.1,1.2-2.7,1.9-4.4,1.9h-0.1c-1.7,0-3.3-0.7-4.4-1.9c-1.1,1.2-2.7,1.9-4.4,1.9
        c-1.7,0-3.3-0.7-4.4-1.9c-1.1,1.2-2.7,1.9-4.4,1.9c-0.8,0-1.5-0.1-2.2-0.4v-2.4c0.6,0.5,1.4,0.7,2.2,0.7c1.5,0,2.8-0.9,3.4-2.2h2
        c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2
        c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2c1.5,0,2.8-0.9,3.4-2.2h2c0.6,1.3,1.9,2.2,3.4,2.2C52.6,42.5,53.1,42.4,53.6,42.2z
        "/>
    </svg>,
    'Yacht': <svg className="fill-current h-10 w-10 mr-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path d="M11.3,38.3h18.9v-29L11.3,38.3z M27.6,35.6H16.2l11.3-17.5V35.6z M32.3,38.3h16.8c0.2-1.1,0.3-2.3,0.3-3.4
        c0-9.9-7.5-18-17.1-19.1C34.6,19,36,22.8,36,27S34.6,35.1,32.3,38.3z M36.8,35.6c1.2-2.7,1.8-5.6,1.8-8.7c0-2.4-0.4-4.7-1.1-7
        c5.6,2.7,9.3,8.4,9.3,14.9c0,0.2,0,0.5,0,0.7L36.8,35.6L36.8,35.6z M48.2,41.5h-36c0.9,2.4,2.3,4.7,4,6.5h28
        C46,46.1,47.3,43.9,48.2,41.5z"/>
    </svg>,
}

export default icons;

