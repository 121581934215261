import React from 'react';
import ReactDOM from 'react-dom';
import {IconTab} from './icon_tab.jsx';
export class TripBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showTopTab: false,
        showBottomtab: false,
    }
  }

  render() {

    let activityIcon = null;
    let activityLabel = null;
    if (this.props.currentActivity.length) {
        activityIcon = this.props.icons[this.props.currentActivity[0].name];
        activityLabel = this.props.currentActivity[0].name;
    } else if (this.props.trip.tags && this.props.trip.tags.activity) {
        activityIcon = this.props.icons[this.props.trip.tags.activity[0].tag_title];
        activityLabel = this.props.trip.tags.activity[0].tag_title;
    }

    let locationIcon = null;
    let locationLabel = null;
    if (this.props.currentLocation.length) {
        locationIcon = this.props.icons[this.props.currentLocation[0].name];
        locationLabel = this.props.currentLocation[0].name;
    } else if (this.props.trip.tags && this.props.trip.tags.area) {
        locationIcon = this.props.icons[this.props.trip.tags.area[0].tag_title];
        locationLabel = this.props.trip.tags.area[0].tag_title;
    }

    let comfortIcon = null;
    if (this.props.trip.vessel_comfort) {
        comfortIcon = this.props.icons[this.props.trip.vessel_comfort];
    }

    return <div className="rounded-lg overflow-hidden w-full flex flex-col sm:flex-row bg-white mt-4 hover:shadow-lg transition ease-in-out duration-300" data-cy="tripblock">
        <div className="w-full sm:w-4/12 md:w-3/12 bg-gray-500">
            <div className="w-full h-full overflow-hidden relative" style={{maxHeight:200}}>
                <div className="absolute left-0 bottom-0 flex flex-col z-20">
                    <IconTab label={locationLabel} icon={locationIcon}></IconTab>
                    <IconTab label={activityLabel} icon={activityIcon}></IconTab>
                </div>

                {/* Image gradient */}
                <div className="absolute left-0 bottom-0 w-full z-10" style={{background: "linear-gradient(180deg,transparent,rgba(2, 2, 45, 0.9))", "height":"60%"}}></div>
                {/* Trip Image */}
                <img className="w-full h-full object-cover bg-gray-200 lazy"
                    src={this.props.trip.image_src}
                    alt={this.props.trip.title}
                />
            </div>
        </div>

        <div className="relative flex-1 flex flex-row flex-wrap items-stretch sm:justify-between p-4">
            <div className="trip-name w-full sm:w-7/12 md:w-7/12 flex content-between flex-wrap">
                <h5 className="w-full mb-2 pb-0 pr-4 text-blue-900 text-lg md:text-xl">
                    <span> {this.props.trip.title} </span>
                </h5>

                <div className="w-full flex flex-col mb-4 md:mb-2">
                    {/* Ship Comfort */}
                    <p className="flex font-sans p-0 m-0 text-xs text-gray-600 leading-none">
                        <span className="self-center">Ship Comfort</span>
                    </p>
                    <div className="pt-1 flex flex-row items-center">
                        {comfortIcon}
                        <p className="text-base">{ this.props.trip.vessel_comfort }</p>
                    </div>
                </div>
            </div>


            <div className="w-full sm:w-5/12 md:w-5/12 flex content-between flex-wrap border-t sm:border-t-0 sm:border-l border-gray-300 sm:pl-2 md:pl-4 pt-4 sm:pt-0">
                <div className="w-6/12 sm:w-full flex flex-col md:flex-row md:mb-4">
                    <p className="w-full md:w-6/12 inline-flex pr-2 font-sans text-gray-700 mb-2 leading-none">
                        <svg className="flex-shrink-0 md:-ml-2 md:-mr-1 fill-current w-8 h-8 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                            <path d="M30,12c-6.5,0-11.8,5.3-11.8,11.8C18.2,30.9,30,48,30,48s11.8-17.1,11.8-24.2C41.8,17.3,36.5,12,30,12z M29.8,28
                            c-2.5,0-4.6-2-4.6-4.6s2-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6S32.3,28,29.8,28z"/>
                        </svg>
                        <span className="mt-2 text-sm">{this.props.trip.tags && this.props.trip.tags.area ? this.props.trip.tags.area['0'].tag_title : ''}</span>
                    </p>
                    <p className="w-full md:w-6/12 inline-flex pr-2 font-sans text-gray-700 mb-2 leading-none">
                        <svg className="fill-current w-8 h-8 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                            <path d="M13.9,14.6v31H46v-31H13.9z M43,42.4H17V23h26V42.4z"/>
                            <rect x="27.3" y="31" width="5.4" height="3.5"/>
                            <rect x="19.8" y="25.8" width="5.4" height="3.5"/>
                            <rect x="27.3" y="36.3" width="5.4" height="3.5"/>
                            <rect x="19.8" y="36.3" width="5.4" height="3.5"/>
                            <rect x="19.8" y="31" width="5.4" height="3.5"/>
                            <rect x="34.9" y="31" width="5.4" height="3.5"/>
                            <rect x="27.3" y="25.8" width="5.4" height="3.5"/>
                            <rect x="34.9" y="25.8" width="5.4" height="3.5"/>
                        </svg>
                        <span className="mt-2 text-sm">{this.props.trip.duration > 1 ? this.props.trip.duration+" Days" : this.props.trip.duration+" Day"}</span>
                    </p>
                </div>

                <div className="w-6/12 sm:w-full flex flex-col md:flex-row items-stretch">
                    <p className="pt-2 sm:pt-0 self-center font-sans text-center sm:text-left text-gray-900 mb-2 leading-none">
                        <span className="inline-block md:block pr-2 text-xs text-gray-600">From</span>
                        <span className="text-base md:text-lg">
                            {this.props.trip.formatted_base_price ? this.props.trip.formatted_base_price : '-'}
                        </span>
                    </p>

                    <a href={'/quote?trip_id='+this.props.trip.id+'&trip='+this.props.trip.title} target="_blank"
                        className="mt-2 sm:mt-0 w-full md:w-auto self-center flex flex-col text-center bg-orange-500 hover:bg-orange-600  text-white hover:bg-orange-700 text-sm font-sans md:ml-auto py-2 px-4 rounded hover:no-underline uppercase transition-colors duration-300 ease-in-out ga-track-trip-btn">
                        Send Details
                    </a>
                </div>
            </div>
        </div>
    </div>
  }
}
