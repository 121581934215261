import {TripBlock} from './trip_block.jsx';
import icons from './icons.jsx';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';

export class TripFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        heading: 'Antarctica Trips',
        trips: [], // loaded on mount, reordered whenever a sort change occurs
        tags: [], // loaded on mount, used to populate all tags on page.
        selectedTags: [], // copied from tags and filtered
        selectedTrips: [], // copied from trips and filtered
        sortOptions: [
            {description: "Popularity (High to Low)", code:'popularlity', field: 'bookings_count', order: 'DESC', selected: 1},
            // {description: "Off the beaten track (High to Low)", code:'wild', field: 'wilderness', order: 'DESC', selected: 0},
            {description: "Price (High to Low)", code: 'base_high_low', field: 'base_price', order: 'DESC', selected: 0},
            {description: "Price (Low to High)", code: 'base_low_high', field: 'base_price', order: 'ASC', selected: 0},
            {description: "Duration (Short to Long)", code: 'duration', field: 'duration', order: 'ASC', selected: 0},
            // {description: "Difficulty (Easy to Hard)", code: 'difficulty', field: 'difficulty', order: 'ASC', selected: 0}
        ],
        ppdMaxBoundary: 99999, // hard upper boundary
        ppdMax: 0,
        activityDroppedDown: 1,
        locationDroppedDown: 1,
        comfortDroppedDown: 0,
        ppdDroppedDown: 0,
        mobileNavShown: 0,
        availableSubs: []
    }
    this.initialSetupQuery = this.initialSetupQuery.bind(this); // Initial setup
    this.getSelectedTags = this.getSelectedTags.bind(this); // Reused in a few places.
    this.getSelectedTrips = this.getSelectedTrips.bind(this); // Reused in a few places.

    this.sortChange = this.sortChange.bind(this); // sorts out the sorting order in memory before calling updateData

    this.updateData = this.updateData.bind(this);
    this.reorderTrips = this.reorderTrips.bind(this); // called by UD - reorders the trips based on the selected option.
    this.updateTags = this.updateTags.bind(this); // called by UD when a tag is changed
    this.removeTag = this.removeTag.bind(this); // called by UD when a tag is deselected at the top
    this.filterTripsByPrice = this.filterTripsByPrice.bind(this); // called by UD when a price is changed.

    this.checkAlreadySelected = this.checkAlreadySelected.bind(this);
    this.dealWithSubTags = this.dealWithSubTags.bind(this);

    // standalone
    this.reset = this.reset.bind(this);
    this.setOriginalTags = this.setOriginalTags.bind(this); // called by Reset to restore the original tags.

    this.showAll = this.showAll.bind(this);

    this.getSelectableSubs = this.getSelectableSubs.bind(this);
    this.populateTagCounts = this.populateTagCounts.bind(this);

    this.scrollPoint = React.createRef()
}

  componentDidMount() {
      this.initialSetupQuery();
  }

  initialSetupQuery() {

    let heading = this.props.heading ? this.props.heading : this.state.heading;

    return fetch(this.props.cms_link+'/trips/get_ant_trip_finderdata', {
        // mode: 'no-cors',
        method: 'POST',
        credentials: 'omit',
        headers: {
            Accept: 'application/json',
        },
        body: JSON.stringify({currency_id: this.props.currency_id})
    })
    .then(res => res.json())
    .then(data => {

        let selectedTags = this.getSelectedTags(data.tags);
        let selectedTrips = this.getSelectedTrips(data.trips, selectedTags);
        let selectedSubs = this.getSelectableSubs(selectedTags, data.tags, selectedTrips);
        let tags = this.populateTagCounts(data.tags, data.trips, data.max_ppd)

        this.setState({
            heading: heading,
            trips: data.trips,
            tags: data.tags,
            selectedTags: selectedTags,
            selectedTrips: selectedTrips,
            ppdMax: data.max_ppd,
            ppdMaxBoundary: data.max_ppd,
            availableSubs: selectedSubs
        })
    });
  };

  getSelectedTags(tags) {

    return tags.filter(function(tag) {
        return tag.selected === 1;
    });
  }

  getSelectedTrips(trips, selectedTags) {

    let selectedTrips = [...trips];
    let tags = [...selectedTags];

    tags.map((tag) => {

        // as we loop over the tags we'll filter/refilter the trips so that the only ones remaining match all criteria.
        selectedTrips = selectedTrips.filter(function(trip) {

            let category = tag.category;

            // removes bad data?
            if (trip.tags && trip.tags[category]) {

                let matches = trip.tags[category].filter(function(tripTag) {
                    return tripTag.tag_title === tag.name;
                });

                if (matches.length) {
                    return trip;
                }
            }
        });
    });

    return selectedTrips; // pass it back so we can update the state in the calling function
  }

  sortChange(event) {
    let options = [...this.state.sortOptions];

    let selectedOption = null;

    options.map((option) => {

        option.selected = 0;

        if (option.code === event.target.value) {
            option.selected = 1;
            selectedOption = option;
        }
    });

    this.setState({sortOptions: options}); // setState here, won't impact anything as nothing else reads from it.
    this.updateData('sortChange', selectedOption);

  }

  reorderTrips(trips, sortOption) {
    // return a 1 or a -1 because apparently Chrome won't sort unless you do!
    if (sortOption.order === "ASC") {
        trips = trips.sort((a, b) => parseInt(a[sortOption.field]) > parseInt(b[sortOption.field]) ? 1: -1);
    } else {
        trips = trips.sort((a, b) => parseInt(a[sortOption.field]) < parseInt(b[sortOption.field]) ? 1: -1);
    }
    return trips;
  }

  updateTags(tags, selectedTag) {

    return [...tags].map((tag) => {
        if (tag.category == selectedTag.category && tag.category != 'interest') {
            tag.selected = 0;
        }

        if (tag.name === selectedTag.name) {
            tag.selected = 1;
        }
        return tag;
    });
  }

  removeTag(tags, selectedTag) {
    tags = tags.map((tag) => {
        if (tag.name === selectedTag.name) {
            tag.selected = 0;
        }
        return tag;
    });
    return tags;
  }

  updateData(type = null, selection = null, value = null) {

    // grab all trips
    let allTrips = [...this.state.trips];
    let allTags = [...this.state.tags];
    let selectedTags = [...this.state.selectedTags];
    let newTrips = [...this.state.trips];

    // if the sort order has changed, reorder
    if (type == 'sortChange') {
        newTrips = this.reorderTrips(allTrips, selection); // selection =  sorting option
        allTrips = [...newTrips];
    }

    // if tags have changed repopulate selected tags
    if (type == 'tagChange') {

        this.executeScroll();

        // we're going to check if the tag is already selected, and if so, just skip on down to the removeTag logic:
        let isDeselect = this.checkAlreadySelected(selectedTags, selection);
        if (isDeselect) {
           type = 'removeTag';
        } else {
            allTags = this.updateTags(allTags, selection); // selection = selected tag
            selectedTags = this.getSelectedTags(allTags);
        }
    }

    if (type == 'removeTag') {
        allTags = this.removeTag(allTags, selection);
        allTags = this.dealWithSubTags(allTags);
        selectedTags = this.getSelectedTags(allTags);
    }

    newTrips = this.getSelectedTrips(newTrips, selectedTags);


    let maximum = this.state.ppdMax;
    if (type == 'priceChange') {
        maximum = value;
    }

    newTrips = this.filterTripsByPrice(newTrips, 0, maximum);

    let availableSubFilters = this.getSelectableSubs(selectedTags, allTags, newTrips);

    allTags = this.populateTagCounts(allTags, allTrips, maximum);

    // set state of all variables.
    this.setState({
        trips: allTrips,
        selectedTrips: newTrips,
        selectedTags: selectedTags,
        ppdMax: maximum,
        availableSubs: availableSubFilters
    });
  }

  filterTripsByPrice(trips, min, max) {

        return trips.filter(function(trip) {
            if (trip.price_per_day) {
                if (trip.price_per_day >= min && trip.price_per_day <= max) {
                    return trip;
                }
            }
        });
  }

  reset() {
      let tags = this.setOriginalTags(this.state.tags);
      let selectedTags = this.getSelectedTags(tags);
      let newTrips = this.getSelectedTrips(this.state.trips, selectedTags);

      let selectedSubs = this.getSelectableSubs(selectedTags, tags, newTrips);
      tags = this.populateTagCounts(tags, this.state.trips, this.state.ppdMaxBoundary);

      this.setState({
        tags: tags,
        selectedTags: selectedTags,
        selectedTrips: newTrips,
        ppdMax: this.state.ppdMaxBoundary,
        availableSubs: selectedSubs
      });
  }

  showAll() {
      let tags = this.state.tags.map((tag) => {
          tag.selected = 0;
          return tag;
      })

      tags = this.populateTagCounts(tags, this.state.trips, this.state.ppdMaxBoundary);

      let selectedSubs = this.getSelectableSubs(tags, tags, this.state.trips);

      this.setState({
        tags: tags,
        selectedTags: [],
        selectedTrips: this.state.trips,
        ppdMax: this.state.ppdMaxBoundary,
        availableSubs: selectedSubs,
        mobileNavShown: 0
      });

    }

  setOriginalTags(tags) {
    return tags.map((tag) => {
        tag.selected = 0;

        if (tag.original == 1) {
            tag.selected = 1;
        }
        return tag;
    });
  }

  getSelectableSubs(selectedTags, tags, selectedTrips) {

    let availableTags = [];
    let tagsToProcess = selectedTags;

    // first things first we'll check to see if a STYLE tag has been selected, and if so return no selectable tags;
    let styleCheck = selectedTags.filter(function(tag) {
        return tag.category == "style";
    })

    if (styleCheck.length) {
        return availableTags;
    }

    // next up we'll check to see if the selectedTags contain the Property tag
    let propertyCheck = selectedTags.filter(function(tag) {
        return tag.category == 'trip_type' && tag.name == "Property Based";
    })

    if (propertyCheck.length) {
        tagsToProcess = propertyCheck;
    }

    // run through each of the selectedTags and make an array of any children tags.
    let children = [];
    tagsToProcess.map((tag) => {
        tag.children_tags.map((child) => {
            children.push(child);
        })
    })

    // grab the relevant tag data from the complete tag list:
    let childrenTags = tags.filter(function(tag) {
        return children.includes(tag.name);
    })

    // from there remove any that are already selected
    let unselectedChildren = childrenTags.filter(function(tag) {
        return tag.selected == 0
    });

    // from there remove any that don't exist in the selectedTrips list.
    unselectedChildren.map((tag) => {
        let found = false;
        selectedTrips.map((trip) => {

            // there's probably an amazing way to do all of this in one but for now I'll just repeat the code, it runs pretty fast anyway!
            if(!found && trip.tags && trip.tags['interest']) {

                let matches = trip.tags['interest'].filter(function(tripTag) {
                    return tripTag.tag_title == tag.name;
                })

                if (matches.length) {
                    availableTags.push(tag);
                    found = true;
                }
            }

            if(!found && trip.tags && trip.tags['style']) {
                let matches = trip.tags['style'].filter(function(tripTag) {
                    return tripTag.tag_title == tag.name;
                })

                if (matches.length) {
                    availableTags.push(tag);
                    found = true;
                }
            }
        })
    })
    return availableTags;
  }

  populateTagCounts(tags, trips, maxPrice) {

    // Oh boy it's only been a week but coming back to this today to add in pricing stuff, it needs comments. Christ.

    // There are three types of tag that we want to populate the tag count for - Build an array and loop them.
    let categories = ['activity', 'area', /*'trip_type'*/ 'comfort'];

    categories.map((category) => {

        // get the tags matching the category.
        let categoryTags = [...tags].filter(function(tag) {
            return tag.category == category;
        })

        // get the tags NOT matching the category, including interest and comfort levels.
        let other_tags = [...tags].filter(function(tag) {
            return tag.category != category;
        })

        // get the selected tags belonging to the OTHER categories.
        let selected_other_tags = [...other_tags].filter(function(tag) {
            return tag.selected == 1;
        })


        // Long comment, but it'll explain this somewhat messy code:
        // Loop over all the tags belonging to the category we're looping over, e.g. Activity => Trekking, Horseriding.
        // Count how many trips in the ALL trips list have the relevant tag and are within the pricing selection.

        categoryTags.map((tag) => {
            let matchingTrips = [...trips].filter(function(trip) {
                if (trip.tags && trip.tags[category]) {

                    let matches = trip.tags[category].filter(function(tripTag) {
                        return tripTag.tag_title === tag.name && trip.price_per_day <= maxPrice;
                    });

                    if (matches.length) {
                        return trip;
                    }
                }
            })

            // Next, filter these results a bit further based on whatever else is selected.
            // e.g. We're looking at Trekking and Horseriding, but maybe TDP and Private are selected.
            // For each combination of other tags, check how many trips match the selection.

            selected_other_tags.map((other_tag) => {
                matchingTrips = matchingTrips.filter(function(trip) {

                    if (trip.tags && trip.tags[other_tag.category]) {

                        let matches = trip.tags[other_tag.category].filter(function(tripTag) {
                            return tripTag.tag_title === other_tag.name && trip.price_per_day <= maxPrice;
                        });

                        if (matches.length) {
                            return trip;
                        }
                    }
                })
            })

            // Update the number of matching trips for each tag at the end based on what you've found.
            tag.available_count = matchingTrips.length;
        })
    })
    return tags;
  }

  checkAlreadySelected(tags, selectedTag) {
     let result = tags.filter(function(tag) {
         return tag.name === selectedTag.name && tag.selected == 1;
     })

     return result.length;
  }

  dealWithSubTags(allTags) {

    let selectedTags = allTags.filter(function(tag) {
        return tag.selected === 1;
    })

    // build up an array of children that should be available based on the selected tags.
    // then run through the selected tags and remove any children that shouldn't be there.

    // We do this because certain children belong to multiple parents, e.g. W Trek is both under Trekking and TDP, so we can't just blindly remove all children of a deselected tag.
    let children = [];
    selectedTags.map((tag) => {
        tag.children_tags.map((child) => {
            children.push(child);
        })
    });

    allTags = allTags.map(function(tag) {

        // return if it's a parent tag, we don't care about these
        if (!tag.is_child) {
            return tag;
        }

        if (!children.includes(tag.name)) {
            tag.selected = false;
        };
        return tag;
    });

    return allTags;
  }

  executeScroll = () => this.scrollPoint.current.scrollIntoView();

  render() {

    var state = this.state;

    let difficulties = [
        {'description': 'Vehicle or Zodiac based with little exertion.'},
        {'description': 'Involving an hour or two of light exercise.'},
        {'description': 'No previous experience is necessary, but you will need to be active for a full day. Hikers will carry a day pack.'},
        {'description': 'Challenging full days of activity, experience is necessary and trekkers will need to carry an 18kg pack.'},
        {'description': 'Must be highly experienced at this activity and prepared for extreme weather, heavy loads and Type 2 fun.'},
    ]

    let wilderness = [
        {'description': 'A popular place with hundreds of visitors every day.'},
        {'description': "You'll see people every hour but on the whole it will be just you and your group."},
        {'description': "You'll see a few other tourists every day or there are a combination of days with commonly visited place and days without commonly visited places."},
        {'description': "A remote trip where you will get off the beaten track."},
        {'description': 'Only a handful of people have ever visited this place and there are no trails or signs of humanity.'}
    ]

    let currentActivity = this.state.selectedTags.filter(function(tag) {
        return tag.category == 'activity';
    });

    let currentTripType = this.state.selectedTags.filter(function(tag) {
        return tag.category == 'trip_type';
    })

    let currentLocation = this.state.selectedTags.filter(function(tag) {
        return tag.category == 'area';
    })

    let sliderOrange = Math.round((this.state.ppdMax / this.state.ppdMaxBoundary) * 100);

    let downChevron = icons['Down-Chevron'];
    let rightChevron = icons['Right-Chevron'];

    return <section className="trip-cards-wrapper bg-grey more-content">
        <div className="row mt-10 md-10 md:mb-20">
            <div className="mt-4 mb-2 md:mb-4 lg:mb-10">
                <h2 className="text-blue-900 text-center lg:border-t-4 lg:border-b border-blue-900 mb-2">
                    <span className="block lg:py-4">{this.state.heading}</span>
                </h2>
                <div className="flex justify-center flex-col lg:hidden items-stretch mt-2">
                    <p className="self-center leading-snug p-0 mb-0 text-blue-900 font-bold text-sm sm:text-base font-sans">
                        {this.state.selectedTrips.length} of&nbsp;
                        <a className="underline text-orange-500 hover:text-orange-600" onClick={this.showAll}><span className="cursor-pointer" data-cy="showAllMobile">{this.state.trips.length} trips</span></a>
                    </p>

                    {this.state.selectedTags.length ?
                        <div className="self-center flex-1 flex flex-row flex-wrap w-full block lg:hidden border-t border-gray-400 mt-2 pt-4">
                            {this.state.selectedTags.length ?
                                <p className="text-sm text-gray-600 self-center p-0 mb-2 mr-2">Filtered by:</p>
                            : null}
                            {this.state.selectedTags.map((tag) =>
                                <button
                                    className="group self-center flex items-center text-center bg-gray-700 hover:bg-gray-800 text-white text-xs font-sans capitalize py-2 pl-2 pr-2 rounded border-0 mr-2 mb-2"
                                    onClick={() => this.updateData('removeTag', tag)}
                                >
                                    <span>{tag.name}</span>
                                    <svg className="self-center text-gray-700 group-hover:opacity-75 group-hover:text-orange-600 fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                        <path id="Path_2864" data-name="Path 2864" d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" fill="#fff"></path>
                                        <g id="Group_794" data-name="Group 794" transform="translate(4.201 4.201)">
                                            <rect id="Rectangle_12896" data-name="Rectangle 12896" width="0.947" height="9.465" rx="0.473" transform="translate(0 0.669) rotate(-45)"></rect>
                                            <rect id="Rectangle_12897" data-name="Rectangle 12897" width="0.947" height="9.465" rx="0.473" transform="translate(6.693 0) rotate(45)"></rect>
                                        </g>
                                    </svg>
                                </button>
                            )}
                        </div>
                    : null}
                </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
                {/* Desktop Nav // Matrin on guide page remove lg:block // */}
                <div className={"hidden md:w-3/12 " + ( this.props.hide_sidebar === "true" ? "" : "lg:block ")}>
                    <nav className="shadow-lg rounded-lg border border-blue-600 md:mr-6 mb-10 flex flex-col bg-white px-4 pb-4 pt-6">
                        {/*-- reset button */}
                        <button className="flex justify-center text-center bg-white hover:bg-orange-500 text-orange-600 hover:text-white border border-orange-500 font-bold text-sm font-sans py-2 px-4 mb-6 mr-0 rounded hover:no-underline transition-colors duration-300 ease-in-out"
                            data-cy="desktopReset"
                            onClick={this.reset}
                        >
                            <svg className="self-center fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                <clipPath id="clip-path">
                                    <rect fill="none"></rect>
                                </clipPath>
                                <path id="Path_189" data-name="Path 189" d="M4.375,8.75c.438,0,.612.262.263.613L3.325,10.675A5.239,5.239,0,0,0,7,12.25,5.292,5.292,0,0,0,12.25,7.7.885.885,0,0,1,14,7.875V8.05A7,7,0,0,1,7,14a7.317,7.317,0,0,1-4.987-2.012l-1.4,1.4c-.35.35-.613.175-.613-.262V9.188A.413.413,0,0,1,.438,8.75Zm5.25-3.5c-.437,0-.613-.263-.262-.612l1.312-1.312A5.239,5.239,0,0,0,7,1.75,5.292,5.292,0,0,0,1.75,6.3.832.832,0,0,1,.875,7,.827.827,0,0,1,0,6.125V5.95A7,7,0,0,1,7,0a7.317,7.317,0,0,1,4.988,2.013l1.4-1.4c.35-.35.613-.175.613.263V4.813a.413.413,0,0,1-.437.438Z"></path>
                            </svg>
                            <span className="self-center">Reset </span>
                        </button>
                        <ul className="list-none ml-0 mb-0">
                            <li className="pb-0" key="key-activityDesk">
                                {/* Activity */}
                                <div className="flex flex-col">
                                    <button className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                        onClick={() => this.setState({activityDroppedDown: !this.state.activityDroppedDown})}
                                    >
                                        <span className="-ml-2 flex items-center justify-center text-blue-800">
                                            <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                <polygon points="22.6,48.4 27.8,35 33,48.4 36.9,48.4 29.7,30 32.1,23.6 33.8,27.8 40,27.8 40,48.4 42.4,48.4 42.4,27.8 43.6,27.8
                                                    43.6,24.2 42.4,24.2 42.4,16.5 40,16.5 40,24.2 36.2,24.2 34.1,18.6 34.2,18.6 34.1,18.6 34.1,18.5 34,18.5 30.8,17.3 18.8,48.4
                                                    "/>
                                                <circle cx="34.1" cy="14" r="2.6"/>
                                                <path d="M29.4,14.1l-6.7-2.6l-5.8,14.7l6.7,2.6L29.4,14.1z M20,24.8l4-10.3l2.3,0.9l-4,10.3L20,24.8z"/>
                                            </svg>
                                        </span>
                                        <span className="ml-2 text-base font-bold">Activity</span>
                                        {this.state.activityDroppedDown ? downChevron : rightChevron }
                                    </button>
                                    {/*-- menu links --*/}
                                    {this.state.activityDroppedDown ?
                                        <div className="bg-white pl-2 pt-2 pb-4 fade-in">
                                            <ul className="read-more-wrap font-sans list-none m-0">
                                                {this.state.tags
                                                    .filter(function(tag) {
                                                        return tag.category == 'activity'
                                                    })
                                                    .map((tag, index) =>
                                                    <li className="pb-2" key={"activityFilterDesk"+index}>
                                                        <div className={"my-px " + (tag.available_count == 0 ? 'disabled' : null)}>
                                                            <input type="radio" id={"activityFilterDesk"+index} checked={tag.selected} onChange={() => this.updateData('tagChange', tag)}/>
                                                            <label className="text-sm text-blue-800 hover:text-orange-600" htmlFor={"activityFilterDesk"+index} >{tag.name} <span className="bg-gray-200 text-gray-900 hover:bg-blue-900 hover:text-white px-2 py-1 rounded-full text-xs font-bold">{tag.available_count}</span></label>
                                                        </div>
                                                    </li>

                                                )}
                                            </ul>
                                        </div>
                                    : null }
                                </div>
                            </li>
                            <li className="pb-0" key="locationDeskKey">
                                <div className="flex flex-col">
                                    <button className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                            onClick={() => this.setState({locationDroppedDown: !this.state.locationDroppedDown})}
                                    >
                                        <span className="-ml-2 flex items-center justify-center text-blue-800">
                                            <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                <path d="M30,12c-6.5,0-11.8,5.3-11.8,11.8C18.2,30.9,30,48,30,48s11.8-17.1,11.8-24.2C41.8,17.3,36.5,12,30,12z M29.8,28
                                                c-2.5,0-4.6-2-4.6-4.6s2-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6S32.3,28,29.8,28z"/>
                                            </svg>
                                        </span>
                                        <span className="ml-2 text-base font-bold">Location</span>
                                        {this.state.locationDroppedDown ? downChevron : rightChevron }
                                    </button>

                                    {this.state.locationDroppedDown ?
                                        <div className="bg-white pl-2 pt-2 pb-4 fade-in">
                                            <ul className="read-more-wrap font-sans list-none m-0">
                                                {this.state.tags
                                                    .filter(function(tag) {
                                                        return tag.category == 'area'
                                                    })
                                                    .map((tag, index) =>
                                                    <li className="pb-2" key={"locationFilterDesk"+index}>
                                                        <div className={"my-px " + (tag.available_count == 0 ? 'disabled' : null)}>
                                                            <input type="radio" id={"locationFilterDesk"+index} checked={tag.selected} onChange={() => this.updateData('tagChange', tag)}/>
                                                            <label className="group text-sm text-blue-800 hover:text-orange-600" htmlFor={"locationFilterDesk"+index} >{tag.name} <span className="bg-gray-200 text-gray-900 hover:bg-blue-900 hover:text-white px-2 py-1 rounded-full text-xs font-bold">{tag.available_count}</span></label>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    : null }
                                </div>
                            </li>
                            <li className="pb-0" key="comfortDeskKey">
                                <div className="flex flex-col">
                                    <button className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                            onClick={() => this.setState({comfortDroppedDown: !this.state.comfortDroppedDown})}
                                    >
                                        <span className="-ml-2 flex items-center justify-center text-blue-800">
                                            <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                <path d="M28,32.6v10.8h2.3V32.6L43,19.9h-6.5l6.4-6.4l-1.6-1.6l-8.1,8.1h-18L28,32.6z M37.4,22.3l-2.5,2.5h-3.2l2.5-2.5H37.4z
                                                    M28.4,24.8h-5l-2.5-2.5h10L28.4,24.8z"/>
                                                <rect x="20.9" y="45.7" width="16.5" height="2.3"/>
                                            </svg>
                                        </span>
                                        <span className="ml-2 text-base font-bold">Comfort</span>
                                        {this.state.comfortDroppedDown ? downChevron : rightChevron }
                                    </button>

                                    {this.state.comfortDroppedDown ?
                                        <div className="bg-white pl-2 pt-2 pb-4 fade-in">
                                            <ul className="read-more-wrap font-sans list-none m-0">
                                                {this.state.tags
                                                    .filter(function(tag) {
                                                        return tag.category == 'comfort'
                                                    })
                                                    .map((tag, index) =>
                                                    <li className="pb-2" key={"comfortFilterDesk"+index}>
                                                        <div className={"my-px " + (tag.available_count == 0 ? 'disabled' : null)}>
                                                            <input type="radio" id={"comfortFilterDesk"+index} checked={tag.selected} onChange={() => this.updateData('tagChange', tag)}/>
                                                            <label className="group text-sm text-blue-800 hover:text-orange-600" for={"comfortFilterDesk"+index} >{tag.name} <span className="bg-gray-200 text-gray-900 hover:bg-blue-900 hover:text-white px-2 py-1 rounded-full text-xs font-bold">{tag.available_count}</span></label>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    : null }
                                </div>
                            </li>
                            <li className="pb-0" key="ppdDeskKey">
                                <div className="flex flex-col">
                                    <button className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200" data-cy="ppdbutton"
                                        onClick={() => this.setState({ppdDroppedDown: !this.state.ppdDroppedDown})}
                                    >
                                        <span className="-ml-2 flex items-center justify-center text-blue-800">
                                            <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                <path d="M28.3,48.1v-4.5c-2.6,0-5.3-0.9-6.9-2l1.1-3c1.6,1.1,3.9,1.9,6.5,1.9c3.2,0,5.3-1.8,5.3-4.4c0-2.5-1.7-4-5.1-5.3
                                                c-4.6-1.8-7.4-3.9-7.4-7.8c0-3.7,2.6-6.5,6.8-7.2v-4.5h2.8v4.4c2.7,0.1,4.5,0.8,5.8,1.6l-1.1,3c-0.9-0.5-2.8-1.5-5.7-1.5
                                                c-3.5,0-4.8,2.1-4.8,3.9c0,2.3,1.7,3.5,5.6,5.1c4.6,1.9,7,4.2,7,8.2c0,3.5-2.5,6.9-7.1,7.6v4.7H28.3z"/>
                                                </svg>
                                        </span>
                                        <span className="ml-2 text-base font-bold">Price Per Day</span>
                                        {this.state.ppdDroppedDown ? downChevron : rightChevron }
                                    </button>
                                    {this.state.ppdDroppedDown ?
                                        <div className="bg-white px-2 pt-2 pb-6 fade-in">
                                            <div id="rangeBox" className="flex flex-col">
                                                <div id="sliderBox" className="w-full flex flex-row my-4">
                                                    <input className="slider w-full"
                                                        type="range"
                                                        id="slider51to100"
                                                        step="10"
                                                        min="0"
                                                        value={this.state.ppdMax}
                                                        max={this.state.ppdMaxBoundary}
                                                        onChange = {(event) => this.updateData('priceChange', 'ppdMax', event.target.value)}
                                                        style={{backgroundImage: "linear-gradient(90deg, rgba(230,108,40,1) "+sliderOrange+"%, rgba(221,228,236,1) "+sliderOrange+"%)"}}
                                                    />
                                                </div>
                                                <div id="inputRange" className="w-full flex flex-row mt-2">
                                                    <input className="w-6/12 text-base mx-auto bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                                                        data-cy="ppdInputDesk"
                                                        type="number" step="10" min="0" max={this.state.ppdMaxBoundary} placeholder="Max" id="max"
                                                        value={this.state.ppdMax}
                                                        onChange = {(event) => this.updateData('priceChange', 'ppdMax', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* // Matrin on guide page remove lg:w-9/12 // */}
                <div className={"w-full ml-auto " + (this.props.hide_sidebar === "true" ? "" : "lg:w-9/12")}>
                    <div className="flex flex-row flex-wrap items-stretch lg:mb-2 py-2 lg:pt-0 bg-white lg:bg-transparent">
                        {/* Mobile Menu Here */}

                        <div className="w-full flex h-10 mx-4 md:mx-0 sm:w-auto self-center">
                            {/* slideout menu */}

                            {this.state.mobileNavShown ?
                            <nav className="flex">
                                <div className="bg-white flex content-start flex-wrap fixed pb-16 w-full sm:w-8/12 md:w-6/12 left-0 top-0 bottom-0 overflow-x-auto shadow-2xl fade-in-left" style = {{ zIndex : 999 }}>
                                    <div className="sticky top-0 z-20 w-full bg-blue-900 flex py-2 px-6 mb-4">
                                    {/* reset filter */}
                                        <button
                                            className="bg-gray-600 hover:bg-orange-500 text-white text-base font-bold font-sans uppercase py-2 px-4 rounded border-0 m-0 flex"
                                            onClick={this.reset}
                                        >
                                            <svg className="self-center text-white fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                                <clipPath id="clip-path">
                                                    <rect width="14" height="14" fill="none"/>
                                                </clipPath>
                                                <path id="Path_189" data-name="Path 189" d="M4.375,8.75c.438,0,.612.262.263.613L3.325,10.675A5.239,5.239,0,0,0,7,12.25,5.292,5.292,0,0,0,12.25,7.7.885.885,0,0,1,14,7.875V8.05A7,7,0,0,1,7,14a7.317,7.317,0,0,1-4.987-2.012l-1.4,1.4c-.35.35-.613.175-.613-.262V9.188A.413.413,0,0,1,.438,8.75Zm5.25-3.5c-.437,0-.613-.263-.262-.612l1.312-1.312A5.239,5.239,0,0,0,7,1.75,5.292,5.292,0,0,0,1.75,6.3.832.832,0,0,1,.875,7,.827.827,0,0,1,0,6.125V5.95A7,7,0,0,1,7,0a7.317,7.317,0,0,1,4.988,2.013l1.4-1.4c.35-.35.613-.175.613.263V4.813a.413.413,0,0,1-.437.438Z"/>
                                            </svg>
                                            <span className="self-center">Reset </span>
                                        </button>
                                        {/* close menu */}
                                        <button
                                            className="bg-transparent hover:bg-orange-500 text-white text-base font-bold font-sans uppercase py-2 px-4 rounded border-0 m-0 ml-auto"
                                            data-cy="doneButtonMob"
                                            onClick={() => this.setState({mobileNavShown: 0})}
                                        >
                                            Done
                                        </button>
                                    </div>
                                    {/* filter lists */}
                                    <ul className="list-none mx-4 w-full pb-40 px-4 sm:px-6">
                                        <li className="pb-0">
                                            {/* filter 1 */}
                                            <div className="flex flex-col">
                                                <button
                                                    className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                                           onClick={() => this.setState({activityDroppedDown: !this.state.activityDroppedDown})}
                                                >
                                                    <span className="-ml-2 flex items-center justify-center text-blue-800">
                                                        <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                            <polygon points="22.6,48.4 27.8,35 33,48.4 36.9,48.4 29.7,30 32.1,23.6 33.8,27.8 40,27.8 40,48.4 42.4,48.4 42.4,27.8 43.6,27.8
                                                                43.6,24.2 42.4,24.2 42.4,16.5 40,16.5 40,24.2 36.2,24.2 34.1,18.6 34.2,18.6 34.1,18.6 34.1,18.5 34,18.5 30.8,17.3 18.8,48.4
                                                            "/>
                                                            <circle cx="34.1" cy="14" r="2.6"/>
                                                            <path d="M29.4,14.1l-6.7-2.6l-5.8,14.7l6.7,2.6L29.4,14.1z M20,24.8l4-10.3l2.3,0.9l-4,10.3L20,24.8z"/>
                                                        </svg>
                                                    </span>
                                                    <span className="ml-2 text-base font-bold">Activity</span>
                                                    {this.state.activityDroppedDown ? downChevron : rightChevron }
                                                </button>
                                                {/*} menu links */}
                                                {this.state.activityDroppedDown ?
                                                    <div className="bg-white pl-2 pt-2 pb-4 fade-in">
                                                        <ul className="read-more-wrap font-sans list-none m-0">

                                                        {this.state.tags
                                                            .filter(function(tag) {
                                                                return tag.category == 'activity'
                                                            })
                                                            .map((tag, index) =>
                                                                <li className="pb-2" key={"activityFilterMobile"+index}>
                                                                    <div className={"my-px " + (tag.available_count == 0 ? 'disabled' : '')}>
                                                                        <input type="radio" id={"activityFilterMobile"+index} checked={tag.selected} onChange={() => this.updateData('tagChange', tag)} />
                                                                        <label className="text-sm text-blue-800 hover:text-orange-600" htmlFor={"activityFilterMobile"+index} > {tag.name} <span className="bg-gray-200 text-gray-900 hover:bg-blue-900 hover:text-white px-2 py-1 rounded-full text-xs font-bold">{tag.available_count}</span> </label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        </ul>
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li className="pb-0">
                                        {/*} filter 2 */}
                                            <div className="flex flex-col">
                                                <button
                                                    className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                                    onClick={() => this.setState({locationDroppedDown: !this.state.locationDroppedDown})}
                                                >
                                                    <span className="-ml-2 flex items-center justify-center text-blue-800">
                                                        <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                            <path d="M30,12c-6.5,0-11.8,5.3-11.8,11.8C18.2,30.9,30,48,30,48s11.8-17.1,11.8-24.2C41.8,17.3,36.5,12,30,12z M29.8,28
                                                                c-2.5,0-4.6-2-4.6-4.6s2-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6S32.3,28,29.8,28z"/>
                                                        </svg>
                                                    </span>
                                                    <span className="ml-2 text-base font-bold">Location</span>
                                                    {this.state.locationDroppedDown ? downChevron : rightChevron }
                                                </button>
                                                {/*} menu links */}
                                                {this.state.locationDroppedDown ?
                                                    <div className="bg-white pl-2 pt-2 pb-4 fade-in">
                                                        <ul className="read-more-wrap font-sans list-none m-0">

                                                        {this.state.tags
                                                            .filter(function(tag) {
                                                                return tag.category == 'area'
                                                            })
                                                            .map((tag, index) =>
                                                                <li className="pb-2" key={"locationFilterMobile"+index} >
                                                                    <div className={"my-px " + (tag.available_count == 0 ? 'disabled' : '')}>
                                                                        <input type="radio" id={"locationFilterMobile"+index} checked={tag.selected} onChange={() => this.updateData('tagChange', tag)} />
                                                                        <label className="text-sm text-blue-800 hover:text-orange-600" htmlFor={"locationFilterMobile"+index} > {tag.name} <span className="bg-gray-200 text-gray-900 hover:bg-blue-900 hover:text-white px-2 py-1 rounded-full text-xs font-bold">{tag.available_count}</span> </label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        </ul>
                                                    </div>
                                                : null}
                                            </div>
                                        </li>

                                        <li className="pb-0">
                                        {/* filter 3 */}
                                            <div className="flex flex-col">
                                                <button
                                                    className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                                    onClick={() => this.setState({comfortDroppedDown: !this.state.comfortDroppedDown})}
                                                >
                                                    <span className="-ml-2 flex items-center justify-center text-blue-800">
                                                        <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                            <path d="M28,32.6v10.8h2.3V32.6L43,19.9h-6.5l6.4-6.4l-1.6-1.6l-8.1,8.1h-18L28,32.6z M37.4,22.3l-2.5,2.5h-3.2l2.5-2.5H37.4z
                                                                M28.4,24.8h-5l-2.5-2.5h10L28.4,24.8z"/>
                                                            <rect x="20.9" y="45.7" width="16.5" height="2.3"/>
                                                        </svg>
                                                    </span>
                                                    <span className="ml-2 text-base font-bold">Comfort</span>
                                                    {this.state.comfortDroppedDown ? downChevron : rightChevron }
                                                </button>
                                                {/*} menu links */}
                                                {this.state.comfortDroppedDown ?
                                                    <div className="bg-white pl-2 pt-2 pb-4 fade-in">
                                                        <ul className="read-more-wrap font-sans list-none m-0">

                                                        {this.state.tags
                                                            .filter(function(tag) {
                                                                return tag.category == 'comfort'
                                                            })
                                                            .map((tag, index) =>
                                                                <li className="pb-2" key={"comfortFilterMobile"+index}>
                                                                    <div className={"my-px " + (tag.available_count == 0 ? 'disabled' : '')}>
                                                                        <input type="radio" id={"comfortFilterMobile"+index} checked={tag.selected} onChange={() => this.updateData('tagChange', tag)} />
                                                                        <label className="text-sm text-blue-800 hover:text-orange-600" htmlFor={"comfortFilterMobile"+index} > {tag.name} <span className="bg-gray-200 text-gray-900 hover:bg-blue-900 hover:text-white px-2 py-1 rounded-full text-xs font-bold">{tag.available_count}</span> </label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        </ul>
                                                    </div>
                                                : null}
                                            </div>
                                        </li>



                                        <li className="pb-0">
                                            {/*} filter 3 */}
                                            <div className="flex flex-col">
                                                <button className="capitalize rounded-none border-l-0 border-b-0 border-r-0 border-gray-300 bg-white m-0 px-2 flex flex-row items-center font-sans text-gray-600 hover:bg-gray-200"
                                                    data-cy="triptypebuttonMob"
                                                    onClick={() => this.setState({ppdDroppedDown: !this.state.ppdDroppedDown})}
                                                >
                                                    <span className="-ml-2 flex items-center justify-center text-blue-800">
                                                        <svg className="fill-current w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                                            <path d="M28.3,48.1v-4.5c-2.6,0-5.3-0.9-6.9-2l1.1-3c1.6,1.1,3.9,1.9,6.5,1.9c3.2,0,5.3-1.8,5.3-4.4c0-2.5-1.7-4-5.1-5.3
                                                                    c-4.6-1.8-7.4-3.9-7.4-7.8c0-3.7,2.6-6.5,6.8-7.2v-4.5h2.8v4.4c2.7,0.1,4.5,0.8,5.8,1.6l-1.1,3c-0.9-0.5-2.8-1.5-5.7-1.5
                                                                    c-3.5,0-4.8,2.1-4.8,3.9c0,2.3,1.7,3.5,5.6,5.1c4.6,1.9,7,4.2,7,8.2c0,3.5-2.5,6.9-7.1,7.6v4.7H28.3z"/>
                                                        </svg>
                                                    </span>
                                                    <span className="ml-2 text-base font-bold">Price Per Day</span>
                                                    {this.state.ppdDroppedDown ? downChevron : rightChevron }
                                                </button>
                                                {/* menu links */}
                                                {this.state.ppdDroppedDown ?
                                                    <div className="bg-white px-4 pt-2 pb-6 fade-in">
                                                        <div id="rangeBox" className="flex flex-col">
                                                            <div id="sliderBox" className="w-full flex flex-row my-4">
                                                                <input className="slider w-full"
                                                                    type="range"
                                                                    id="slider51to100"
                                                                    step="10"
                                                                    min="0"
                                                                    value={this.state.ppdMax}
                                                                    max={this.state.ppdMaxBoundary}
                                                                    onChange = {(event) => this.updateData('priceChange', 'ppdMax', event.target.value)}
                                                                    style={{backgroundImage: "linear-gradient(90deg, rgba(230,108,40,1) "+sliderOrange+"%, rgba(221,228,236,1) "+sliderOrange+"%)"}}
                                                                />
                                                            </div>
                                                            <div id="inputRange" className="w-full flex flex-row mt-4">
                                                                <input className="w-5/12 text-base mx-auto bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                                                                    data-cy="ppdInputMob"
                                                                    type="number" step="10" min="0" max={this.state.ppdMaxBoundary} placeholder="Max" id="max"
                                                                    value={this.state.ppdMax}
                                                                    onChange = {(event) => this.updateData('priceChange', 'ppdMax', event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </li>
                                        <li className="pb-0">
                                            <div className="w-full pt-6 border-t border-gray-300">
                                                <button className="w-full flex justify-center text-center bg-white hover:bg-orange-500 text-orange-600 hover:text-white border border-orange-500 text-base font-sans py-2 px-4 mb-6 mr-0 rounded hover:no-underline transition-colors duration-300 ease-in-out"
                                                    onClick={this.showAll}
                                                >
                                                    See all trips
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                             : null}
                            {/*} menu trigger */}
                            <button
                                className="h-full mb-2 w-full ml-0 capitalize text-base bg-orange-500 hover:bg-orange-600  border-0 hover:bg-orange-700 text-white hover:text-white font-bold mr-0 ml-0 md:ml-4 mb-0 py-4 px-8 md:px-4 rounded inline-flex lg:hidden items-center justify-center" style={{minWidth:"200px"}}
                                data-cy="mobileSidebarButton"
                                onClick={() => this.setState({mobileNavShown: 1})}
                            >
                                <svg className="text-white fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.334 17.712">
                                    <path id="Path_2883" data-name="Path 2883" d="M1068.438,567.662H1048.1l5.083,6.446,3.563,4.518v6.749l3.04-1.556v-5.192l3.563-4.518Z" transform="translate(-1048.104 -567.662)"/>
                                </svg>
                                Filters
                            </button>
                        </div>

                        <div className="mr-4 lg:mr-0 self-center flex flex-row items-stretch ml-auto lg:order-2">
                            <p className="hidden lg:inline self-center mb-0 p-0 text-base text-gray-600">Sort By:</p>
                            <div className="md:ml-2 hidden sm:inline-block relative">
                                <select id="sort_select" className="block appearance-none bg-gray-200 lg:bg-white lg:border border-grey-300 py-3 px-2 pr-6 rounded font-sans md:text-sm text-base"
                                    onChange={(event) => this.sortChange(event)}
                                >
                                    {this.state.sortOptions.map((option, index) => <option key={index} value={option.code}>{option.description}</option>)}
                                </select>
                                <div className="pointer-events-none absolute top-0 mt-4 mr-2 right-0 flex items-center text-gray-600">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                                </div>
                            </div>
                        </div>

                        <div className="w-full bg-white lg:bg-transparent lg:w-auto self-center flex items-stretch lg:order-1 md:mr-auto">

                            <div className="hidden lg:flex items-stretch">
                                <p className="self-center leading-snug p-0 mb-0 text-blue-900 font-bold text-sm sm:text-base font-sans">
                                    {this.state.selectedTrips.length} of&nbsp;
                                    <a className="underline text-orange-500 hover:text-orange-600" onClick={this.showAll}><span className="cursor-pointer" data-cy="showAllDesk">{this.state.trips.length} trips</span></a>
                                </p>
                                <div className="self-center flex-1 flex flex-row flex-wrap border-l border-gray-400 pl-4 ml-4 ">
                                    {this.state.selectedTags.length ?
                                        <p className="text-base text-gray-600 self-center p-0 my-1 mr-2">Filtered by:</p>
                                    : null}
                                    {this.state.selectedTags.map((tag) =>
                                        <button
                                            className="group self-center flex items-center text-center bg-gray-700 hover:bg-gray-800 text-white text-xs capitalize py-2 pl-2 pr-2 rounded border-0 mr-2 my-1"
                                            data-cy="selectedSubTag"
                                            onClick={() => this.updateData('removeTag', tag)}
                                            >
                                            <span>{tag.name}</span>
                                            <svg className="self-center text-gray-700 group-hover:opacity-75 group-hover:text-orange-600 fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path id="Path_2864" data-name="Path 2864" d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" fill="#fff"></path>
                                                <g id="Group_794" data-name="Group 794" transform="translate(4.201 4.201)">
                                                    <rect id="Rectangle_12896" data-name="Rectangle 12896" width="0.947" height="9.465" rx="0.473" transform="translate(0 0.669) rotate(-45)"></rect>
                                                    <rect id="Rectangle_12897" data-name="Rectangle 12897" width="0.947" height="9.465" rx="0.473" transform="translate(6.693 0) rotate(45)"></rect>
                                                </g>
                                            </svg>
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Mobile Narrow your Result Here */}
                            {this.state.availableSubs.length ?
                                <div className="self-center mt-2 p-2 flex-1 flex lg:hidden justify-center md:flex-row flex-wrap border-t border-gray-300 lg:border-0">
                                    <p className="hidden sm:inline-block text-base text-gray-600 self-center p-0 mb-0 mr-2">Narrow your result:</p>
                                    {this.state.availableSubs.map((subTag, index) =>
                                        <button
                                            key={index}
                                            className="self-center text-center border border-orange-500 bg-white hover:bg-orange-600  text-orange-600 hover:text-white text-xs font-sans capitalize py-2 px-2 rounded hover:no-underline mb-2 mr-2"
                                            onClick={() => this.updateData('tagChange', subTag)}
                                        >
                                        {subTag.name}
                                        </button>
                                    )}
                                </div>
                            : null }
                        </div>

                    </div>
                    <div className="rounded-lg w-full md:-mr-10 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-200er scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch pb-10 px-4 border border-gray-300 trip-table relative">
                        {this.state.availableSubs.length ?
                            <div className="sticky top-0 z-30 shadow-md w-full bg-white px-4 py-2 flex-1 hidden lg:flex flex-row flex-wrap">
                                <p className="text-base text-gray-600 self-center p-0 mb-0 mr-2">Narrow your result:</p>
                                {this.state.availableSubs.map((subTag, index) =>
                                    <button
                                        key={index}
                                        className="self-center text-center border border-orange-500 bg-white hover:bg-orange-600  text-orange-600 hover:text-white text-xs font-sans capitalize py-2 px-2 rounded hover:no-underline my-2 mr-2"
                                        data-cy="subtagButton"
                                        onClick={() => this.updateData('tagChange', subTag)}
                                        >
                                        {subTag.name}
                                    </button>
                                )}
                            </div>
                        : null }

                        <span ref={this.scrollPoint} ></span>

                        {this.state.selectedTrips.map((trip, index) =>
                            <TripBlock
                                index={index}
                                key={index}
                                trip={trip}
                                icons={icons}
                                currentActivity={currentActivity}
                                currentTripType={currentTripType}
                                currentLocation={currentLocation}
                            ></TripBlock>
                        )}

                        <div className="overflow-hidden rounded w-full h-32 flex flex-row items-stretch bg-blue-900 mt-4 mb-10 hover:shadow-lg transition ease-in-out duration-300 relative">
                            <div style={{maxWidth:"30%"}}>
                                <div className="w-full h-full overflow-hidden relative">
                                    <div className="absolute left-0 bottom-0 h-full w-full z-10" style={{background: "linear-gradient(90deg,transparent,rgb(42, 67, 101))"}}></div>
                                    <img className="lazy w-full h-full object-cover" src='https://swoop-antarctica.imgix.net/Emperor_Penguins-ANI.jpg?auto=format,enhance,compress&amp;fit=crop&amp;w=320&amp;h=0&amp;q=40' alt="Patagonia Travel" />
                                </div>
                            </div>
                            <div className="flex-1 flex items-stretch">
                                <a className="self-center w-full flex flex-row items-stretch pl-2 py-4 pr-2 sm:p-6 hover:no-underline">
                                    <p className="self-center text-xl text-white m-0 leading-none">View all
                                        <span className="text-base sm:pl-2 hover:underline flex sm:inline-flex flex-row text-orange-600 font-sans font-bold cursor-pointer" onClick={this.showAll}>
                                            {this.state.trips.length} Antarctica trips
                                            <svg className="inline sm:hidden ml-2 fill-current self-center w-2 h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                    <rect width="16" height="16" fill="none"/>
                                                    </clipPath>
                                                </defs>
                                                <g id="Component_135_5" data-name="Component 135 – 5" transform="translate(16 16) rotate(180)" style={{clipPath: "url(#clip-path)"}}>
                                                    <path id="Path_10" data-name="Path 10" d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z" transform="translate(16 16) rotate(180)"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </p>
                                    <button
                                        onClick={this.showAll}
                                        className="ml-auto hidden sm:block self-center flex flex-col text-center bg-orange-500 hover:bg-orange-600
                                        text-white border-0 hover:bg-white text-base font-sans mx-2 mb-0 py-2 px-4 rounded uppercase">
                                    Show All
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  }
}