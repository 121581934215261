import React from 'react';
import ReactDOM from 'react-dom';

export class IconTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showTab: false
    }
  }

  render() {

    {/* If there's no icon we don't show */}
    if (!this.props.icon) return null;

    return <div
      className="flex"
      onClick={() => this.setState({showTab: !this.state.showTab})}
      onMouseEnter={() => this.setState({showTab: true})}
      onMouseLeave={() => this.setState({showTab: false})}
    >
        <div
            className="flex items-stretch mb-2 bg-white rounded-r-full cursor-pointer">
            <div className="flex h-8 pr-2" style={{color:"#000"}}>
                {this.props.icon}
            </div>
            {this.state.showTab ?
                    <span
                        className="self-center text-xs font-sans -ml-2 pr-4"
                        >{this.props.label}
                    </span>
            : null}
        </div>
    </div>
  }
}
